const config = {
  apiUrl: process.env.REACT_APP_API_URL,
  ssoHost: process.env.REACT_APP_SSO_HOST,
  webSocketHost: process.env.REACT_APP_WS_HOST,
  activityUpdateInterval: 1000 * 10,
  colours: {
    approval: '#136538',
    rejection: '#B60218',
    hold: '#CBBE45',
    upload: '#000000',
    reupload: '#999999',
    option: '#6B8EA2'
  }
};

export default config;
