import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import Loader from '@yic/loader';
import Button from '@yic/button';
import Icon from '@yic/icon';
import useInterval from '../../hooks/useInterval';
import { markAllAsSeen, addNotifications } from '../../redux/actions';
import { CLEAR_ERROR } from '../../redux/constants';
import Notification from './Notification';
import ErrorMessage from '../errors/ErrorMessage';
import { initialiseWS } from '../../utils/webSocket';
import { getToken } from '../../services/authentication';

const Container = styled.div`
  display: grid;
  margin: 2.5rem 2.9rem;
  grid-row-gap: 1rem;
`;

const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    margin: 0.2rem 0;
    font-family: Arial, sans-serif;
    font-size: 1.3rem;
    font-style: italic;
  }

  > div:first-of-type {
    margin: 1rem auto;
    height: 4rem;
    width: 4rem;
  }
`;

const Header = styled.div`
  margin: 0 0 1rem;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

const UnreadMessage = styled.div`
  font-size: 1.8rem;
  font-family: Georgia, sans-serif;
  font-style: italic;
  flex-grow: 1;
`;

const UnreadCount = styled.span`
  font-size: 2.2rem;
`;

const plural = (numberOfOptions) => (numberOfOptions !== 1 ? 's' : '');

const Notifications = () => {
  const notifications = useSelector((s) => s.notifications.notifications);
  const unreadCount = useSelector((s) => s.notifications.unreadCount);
  const status = useSelector((s) => s.notifications.status);
  const error = useSelector((s) => s.notifications.error);

  const dispatch = useDispatch();

  const websocket = useRef(WebSocket);
  const PING_INTERVAL = 60000;

  const sendPing = () =>
    websocket.current.send(JSON.stringify({ action: 'ping' }));

  useInterval(() => {
    if (websocket.current.readyState === WebSocket.OPEN) {
      sendPing();
    }
  }, PING_INTERVAL);

  // TODO: Implement retry logic for reconnect
  const reconnect = () => {
    websocket.current = initialiseWS({
      type: 'notification',
      authorization: getToken()
    });
  };

  useEffect(() => () => dispatch({ type: CLEAR_ERROR }), [dispatch]);

  useEffect(() => {
    websocket.current = initialiseWS({
      type: 'notification',
      authorization: getToken()
    });
  }, []);

  useEffect(() => {
    websocket.current.onopen = () => {};

    websocket.current.onmessage = ({ data }) => {
      const message = JSON.parse(data);

      if (message.type === 'notification') {
        dispatch(addNotifications(message.data));
      }
    };

    websocket.current.onerror = () => {
      console.error(
        'Error: Unexpected error occurred with websocket connection, retrying...'
      );
    };

    websocket.current.onclose = (event) => {
      if (!event.wasClean) {
        console.error(
          `[close] Connection closed, code=${event.code} reason=${event.reason}`
        );
      }
      return reconnect();
    };
  });

  return (
    <Container>
      <Helmet>
        <title>Notifications - Backstage</title>
      </Helmet>
      {status === 'error' && (
        <ErrorMessage
          type="Notifications"
          code={error.code}
          message={error.message}
        />
      )}
      {status === 'fetching' && (
        <LoaderContainer>
          <div>
            <Loader />
          </div>
          <p>Fetching notifications, please wait...</p>
        </LoaderContainer>
      )}
      {!['fetching', 'error'].includes(status) && (
        <>
          <Header>
            <UnreadMessage>
              {unreadCount ? <UnreadCount>{unreadCount}</UnreadCount> : 'No'}
              {` unread WS notification${plural(unreadCount)}`}
            </UnreadMessage>
            <Button.Secondary
              onClick={() => dispatch(markAllAsSeen())}
              size="small"
            >
              <Icon name="confirmation" colour="green" />
              Mark all as Read
            </Button.Secondary>
          </Header>

          {notifications.map((data) => (
            <Notification key={data.activityId} data={data} />
          ))}
        </>
      )}
    </Container>
  );
};

export default Notifications;
