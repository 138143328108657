import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import FormControl from '@yic/form-control';
import PropTypes from 'prop-types';
import Button from '@yic/button';
import * as R from 'ramda';
import Loader from '@yic/loader';
import Checkbox from '../Checkbox';
import useFilters from '../../hooks/useFilters';
import ErrorMessage from '../errors/ErrorMessage';
import history from '../../services/history';
import { parseSetUrlQuery, parseDate } from '../../utils/url';

const FiltersContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
  grid-gap: 1.8rem 2.4rem;
`;

const CalendarInput = styled(FormControl.Calendar)`
  input {
    color: black;
  }

  .react-calendar {
    width: 30rem;
    white-space: nowrap;
  }

  .CalendarDay__today {
    color: red;
  }
`;

const FilterPairContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  p {
    margin: 0 0 0.9rem 0;
    font-size: 1.2rem;

    @media only screen and (max-width: 767px) {
      font-size: 1.4rem;
    }
  }

  > div {
    display: flex;
    height: 100%;
    align-items: center;
    margin-left: 0.1rem;
  }

  label:nth-of-type(2) {
    margin-left: 1rem;
  }
`;

const CheckboxWrapper = styled.div`
  margin-right: 2.8rem;
`;

const SelectedFiltersContainer = styled.div`
  margin-top: 1.5rem;
  display: flex;
  flex-direction: row;
  white-space: nowrap;
`;

const FilterMessage = styled.div`
  color: ${({ active }) => (active ? '#000' : '#9B9B9B')};
  font-size: 1.4rem;
  line-height: 2.3rem;
  display: inline-block;
`;

const ClearFiltersButton = styled.button`
  background: none;
  border: none;
  color: #000;
  font-size: 1.4rem;
  line-height: 1.8rem;
  margin-left: 2.5rem;
  cursor: pointer;
  padding: 0;
  height: 2rem;
  border-bottom: 1px solid #000;

  &:hover,
  &:focus {
    border-bottom: 1px solid transparent;
  }
`;

const ShowImagesButton = styled(Button.Primary)`
  top: 2.15rem;
  position: relative;
  max-height: 3.8rem;
  height: 3.8rem;
  margin-bottom: 2.15rem;
`;

const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    margin: 0.2rem 0;
    font-family: Arial, sans-serif;
    font-size: 1.3rem;
    font-style: italic;
  }

  > div:first-of-type {
    margin: 1rem auto;
    height: 4rem;
    width: 4rem;
  }
`;

const getYearMonthDay = (date) => {
  const year = date.getFullYear();
  const month = `${date.getMonth() + 1}`.padStart(2, 0);
  const day = `${date.getDate()}`.padStart(2, 0);

  return `${year}-${month}-${day}`;
};

const setToday = R.pipe(getYearMonthDay, parseDate);

const ContactSheetsForm = ({ submitSearch, isFetching }) => {
  const [activeOptionalFilters, setActiveOptionalFilters] = useState(0);
  const [isMissingDate, setIsMissingDate] = useState(false);
  const [autoSearch, setAutoSearch] = useState(false);
  const {
    isFetchingOptions,
    optionsFetchError,
    options,
    selection,
    setSelection,
    selectionLength,
    getSelectedValue
  } = useFilters({
    date: setToday(new Date()),
    business: 'NAP'
  });

  const onFormSubmit = useCallback(
    (event) => {
      if (event) event.preventDefault();

      if (!selection.date) {
        setIsMissingDate(true);
        return;
      }

      const activeFilters = R.reject(R.isEmpty, selection);

      activeFilters.date = getYearMonthDay(activeFilters.date);

      submitSearch(activeFilters);
    },
    [selection, submitSearch]
  );

  useEffect(() => {
    if (options && history.location.search) {
      const filters = parseSetUrlQuery(history.location.search);

      if (filters) {
        setSelection({ type: 'overwriteAll', filters });
        setAutoSearch(true);
      }
    }
  }, [options, setSelection]);

  useEffect(() => {
    if (autoSearch) {
      setAutoSearch(false);
      onFormSubmit();
    }
  }, [autoSearch, onFormSubmit]);

  useEffect(R.pipe(R.F, setIsMissingDate), [selection.date]);

  useEffect(() => {
    if (isFetching) setActiveOptionalFilters(selectionLength - 2);
  }, [isFetching, selectionLength]);

  const resetOptionalFilters = () => {
    setActiveOptionalFilters(0);
    setSelection({ type: 'resetOptional' });
  };

  const onInputChange = (filter) => (value) => {
    setSelection({ type: 'set', filter, value });
  };

  const onCheckboxChange = (filter) => (value) =>
    selection[filter] === value
      ? setSelection({ type: 'clear', filter })
      : setSelection({ type: 'set', filter, value });

  const onDropdownChange = (filter) => ({ target }) => {
    setSelection({ type: 'set', filter, value: target.id });
  };

  const filterFunction = (option, pattern) =>
    option.name.toLowerCase().includes(pattern.toLowerCase());

  if (isFetchingOptions) {
    return (
      <LoaderContainer>
        <div>
          <Loader />
        </div>
        <p>Fetching latest filter options, please wait...</p>
      </LoaderContainer>
    );
  }

  if (optionsFetchError) {
    return (
      <ErrorMessage
        type="Contact Sheets"
        code={optionsFetchError.code}
        message={optionsFetchError.message}
      />
    );
  }

  if (options) {
    return (
      <form onSubmit={onFormSubmit}>
        <FiltersContainer>
          <CalendarInput
            label="Date*"
            type="date"
            size="small"
            defaultValue={selection.date}
            onChange={onInputChange('date')}
            max={setToday(new Date())}
            validationState={isMissingDate ? 'error' : null}
            validationMessage="Please select a date"
          />

          <FilterPairContainer>
            <p>Business*</p>
            <FormControl.RadioGroup
              onChange={onInputChange('business')}
              name="test"
            >
              {options.business.map(({ name }) => (
                <FormControl.Radio
                  key={`${name}-business`}
                  value={name}
                  defaultChecked={selection.business === name}
                >
                  {name}
                </FormControl.Radio>
              ))}
            </FormControl.RadioGroup>
          </FilterPairContainer>

          <FormControl.SimpleDropdown
            key={`${selection.location}-location`}
            label="Location"
            name="Location"
            size="small"
            value={getSelectedValue('location')}
            options={options.location}
            onChange={onDropdownChange('location')}
          />

          <FilterPairContainer>
            <p>Image Type</p>
            <div>
              {options.imageType.map(({ name }) => (
                <CheckboxWrapper key={`${name}-imageType`}>
                  <Checkbox
                    onClick={onCheckboxChange('imageType')}
                    checked={selection.imageType === name}
                    label={name}
                  />
                </CheckboxWrapper>
              ))}
            </div>
          </FilterPairContainer>

          <FormControl.Dropdown
            key={`${selection.setType}-photographer`}
            filterOption={filterFunction}
            label="Photographer"
            name="Photographer"
            size="small"
            value={getSelectedValue('photographer')}
            options={options.photographer.filter(
              ({ id, location }) =>
                !id ||
                !location ||
                !selection.location ||
                location === selection.location
            )}
            onChange={onDropdownChange('photographer')}
          />

          <FormControl.SimpleDropdown
            key={`${selection.setType}-setType`}
            label="Set Type"
            name="Set Type"
            size="small"
            value={getSelectedValue('setType')}
            options={options.setType}
            onChange={onDropdownChange('setType')}
          />

          <FilterPairContainer>
            <p>Set Time</p>
            <div>
              {options.setTime.map(({ name }) => (
                <CheckboxWrapper key={`${name}-setTime`}>
                  <Checkbox
                    onClick={onCheckboxChange('setTime')}
                    checked={selection.setTime === name}
                    label={name}
                  />
                </CheckboxWrapper>
              ))}
            </div>
          </FilterPairContainer>

          <FormControl.Dropdown
            key={`${selection.designer}-designer`}
            filterOption={filterFunction}
            label="Designer"
            name="Designer"
            size="small"
            value={getSelectedValue('designer')}
            options={options.designer.filter(
              ({ id, businesses }) =>
                !id || !businesses || businesses.includes(selection.business)
            )}
            onChange={onDropdownChange('designer')}
          />

          <ShowImagesButton size="small" type="submit" disabled={isFetching}>
            Search Images
          </ShowImagesButton>
        </FiltersContainer>

        <SelectedFiltersContainer>
          {activeOptionalFilters > 0 && (
            <>
              <FilterMessage active>
                {`Optional filters applied (${activeOptionalFilters})`}
              </FilterMessage>
              <ClearFiltersButton type="reset" onClick={resetOptionalFilters}>
                Clear All
              </ClearFiltersButton>
            </>
          )}

          {!activeOptionalFilters && (
            <FilterMessage>No optional filters have been applied</FilterMessage>
          )}
        </SelectedFiltersContainer>
      </form>
    );
  }

  return null;
};

ContactSheetsForm.propTypes = {
  submitSearch: PropTypes.func.isRequired,
  isFetching: PropTypes.bool
};

ContactSheetsForm.defaultProps = {
  isFetching: false
};

export default ContactSheetsForm;
