import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Icon from '@yic/icon';
import Heading from '@yic/heading';

const Container = styled.div`
  font-size: 1.6rem;
`;

const BigHeading = styled(Heading.Level1)`
  font-size: 6.4rem;
  margin-bottom: 2rem;
`;

const SmallHeading = styled(Heading.Level4)`
  font-size: 1.8rem;
  line-height: 2.8rem;
`;

const Contact = styled.div`
  margin: 3rem 0;
`;

const IconContainer = styled.div`
  margin: 0 1rem;
`;

const Message = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  background-color: #e0effa;
  color: #103855;
  margin: 1.4rem 0 0;
  padding: 0.8rem 0;
  width: 50%;
  border-radius: 0.2rem;
`;

const Ul = styled.ul`
  list-style: none;
  padding: 0;

  svg {
    display: inline-block;
    top: 0.5rem;
    position: relative;
  }

  p {
    display: inline-block;
    margin-left: 1.1rem;
  }
`;

const Anchor = styled.a`
  text-decoration: underline;

  &:hover {
    color: #000;
  }
`;

const ErrorMessage = ({ type, code, message }) => (
  <Container>
    <BigHeading>{type} Error</BigHeading>
    <p>An error occurred, please try again</p>
    <Message>
      <IconContainer>
        <Icon name="Information" size={23} colour="#103855" />
      </IconContainer>
      <span>
        {code && `Error code: ${code}`} {message}
      </span>
    </Message>
    <Contact>
      <SmallHeading>
        Alternatively, please call or raise a ticket with Service Desk:
      </SmallHeading>
      <Ul>
        <li>
          <Icon name="mobile" size={24} colour="#010101" />
          <p>Extension: 4700</p>
        </li>
        <li>
          <Icon name="External-link" size={24} colour="#010101" />
          <p>
            <Anchor
              href="https://ynap.service-now.com/sp?id=sc_cat_item&sys_id=bf1adf431b004410ffbbea08bd4bcbfa&sysparm_category=fffc79511b9f7380d39ded3fad4bcb40"
              target="_blank"
            >
              Service Now - Report Problem
            </Anchor>
          </p>
        </li>
      </Ul>
    </Contact>
  </Container>
);

ErrorMessage.propTypes = {
  type: PropTypes.string,
  code: PropTypes.string,
  message: PropTypes.string.isRequired
};

ErrorMessage.defaultProps = {
  type: '',
  code: undefined
};

export default ErrorMessage;
