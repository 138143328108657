import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Icon from '@yic/icon';
import moment from 'moment';
import ImageFrame from './ImageFrame';
import ImageTooltip from './ImageTooltip';
import Img from './Img';
import ImageDetails from './ImageDetails';
import StatusButtons from '../StatusButtons';

const MainImageContainer = styled.div`
  margin: 1rem 0 1.2rem 0;
  height: 100%;
`;

const HeaderFooter = styled.div`
  display: flex;
  align-items: flex-end;
`;

const ShotType = styled.div`
  font-weight: bold;
  margin-right: 0.3rem;
`;

const Grower = styled.div`
  display: flex;
  flex-grow: 1;
`;

const DetailToggle = styled.span`
  display: flex;
  margin-left: 0.8rem;
  white-space: nowrap;
  cursor: pointer;
`;

const DetailTitle = styled.div`
  border-bottom: 1px solid grey;

  ${DetailToggle}:hover & {
    border-bottom: 1px solid black;
  }
`;

const UploadedAgo = styled.div`
  font-style: italic;
  text-align: right;
`;

const LoaderIconWrapper = styled.span`
  margin-left: 0.3rem;
  height: 1.1rem;
  width: 1rem;
`;

const DetailMenuItem = ({ onClick, arrowDirection, children }) => (
  <DetailToggle onClick={onClick}>
    {children}
    <LoaderIconWrapper>
      <Icon name={`arrow-${arrowDirection}`} size={10} colour="black" />
    </LoaderIconWrapper>
  </DetailToggle>
);

DetailMenuItem.propTypes = {
  arrowDirection: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired
};

const plural = (numberOfOptions) => (numberOfOptions > 1 ? 's' : '');

const ImageResult = ({ data, updateImageStatus }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedTab, setSelectedTab] = useState();

  const itemRef = useRef(null);

  const {
    shotType,
    photographer,
    setTime,
    setType,
    location,
    lastImageUpdate,
    numberOfComments,
    numberOfVersions,
    numberOfOptions,
    approvalStatus
  } = data;

  useEffect(() => {
    if (isExpanded && itemRef.current) {
      setTimeout(() => {
        if (itemRef.current && isExpanded) {
          itemRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      }, 50);
    }
  }, [isExpanded]);

  const onDetailMenuItemClick = (detailType) => () => {
    setIsExpanded(!isExpanded);
    setSelectedTab(detailType);
  };

  return (
    <ImageFrame
      isExpanded={isExpanded}
      approvalStatus={approvalStatus}
      ref={itemRef}
    >
      <HeaderFooter>
        <ShotType>{shotType.toUpperCase()}</ShotType>

        {!isExpanded && (
          <Grower>
            {numberOfOptions > 0 && (
              <DetailMenuItem
                onClick={onDetailMenuItemClick(1)}
                arrowDirection="down"
              >
                <DetailTitle>
                  {numberOfOptions} Option{plural(numberOfOptions)}
                </DetailTitle>
              </DetailMenuItem>
            )}

            <DetailMenuItem
              onClick={onDetailMenuItemClick(2)}
              arrowDirection="down"
            >
              <DetailTitle>
                {!!numberOfComments && `${numberOfComments} `}
                Comment
                {numberOfComments > 1 && 's'}
              </DetailTitle>
            </DetailMenuItem>

            {numberOfVersions > 1 && (
              <DetailMenuItem
                onClick={onDetailMenuItemClick(3)}
                arrowDirection="down"
              >
                <DetailTitle>{numberOfVersions} Versions</DetailTitle>
              </DetailMenuItem>
            )}
          </Grower>
        )}

        {isExpanded && (
          <Grower>
            <DetailMenuItem
              onClick={() => setIsExpanded(false)}
              arrowDirection="up"
            >
              <DetailTitle>Close</DetailTitle>
            </DetailMenuItem>
          </Grower>
        )}

        <UploadedAgo>
          {`Uploaded ${moment(lastImageUpdate).fromNow()}`}
        </UploadedAgo>

        <ImageTooltip data={data} />
      </HeaderFooter>

      {!isExpanded && (
        <MainImageContainer>
          <Img data={data} />
        </MainImageContainer>
      )}

      {isExpanded && (
        <ImageDetails
          image={data}
          close={onDetailMenuItemClick()}
          selectedTab={selectedTab}
        />
      )}

      <HeaderFooter>
        <Grower>
          {photographer}, {location}
        </Grower>
        <>
          {setType}, {setTime}
        </>
      </HeaderFooter>
      <StatusButtons
        currentStatus={approvalStatus}
        updateImageStatus={updateImageStatus}
      />
    </ImageFrame>
  );
};

ImageResult.propTypes = {
  data: PropTypes.shape({
    business: PropTypes.string,
    filename: PropTypes.string,
    productId: PropTypes.string,
    shotType: PropTypes.string,
    lastImageUpdate: PropTypes.number,
    url: PropTypes.string,
    photographer: PropTypes.string,
    setTime: PropTypes.string,
    setType: PropTypes.string,
    location: PropTypes.string,
    numberOfOptions: PropTypes.number,
    numberOfComments: PropTypes.number,
    numberOfVersions: PropTypes.number,
    approvalStatus: PropTypes.string
  }).isRequired,
  updateImageStatus: PropTypes.func.isRequired
};

export default ImageResult;
