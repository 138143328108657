import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import Logo from '@fabric/logo';
import Icon from '@fabric/icon';
import decode from 'jwt-decode';
import * as R from 'ramda';
import Header, { NavigationArea, LogoArea, UserDetails } from '@fabric/header';
import Navigation, { NavItem } from '@fabric/navigation';
import { NavLink } from 'react-router-dom';
import { getNotifications, updateNotifications } from '../redux/actions';
import { getToken } from '../services/authentication';
import config from '../config';

const UserName = styled.div`
  font-size: 1.4rem;
`;

const ImageCount = styled.div`
  position: absolute;
  bottom: 1.2rem;
  border-radius: 1rem;
  padding: 0.24rem 0.5rem;
  background-color: red;
  color: white;
  font-size: 0.8rem;
  height: 1.4rem;
  left: 1rem;
`;

const BellContainer = styled.div`
  position: relative;
`;

const AppHeader = () => {
  const [user, setUser] = useState({});
  const unreadCount = useSelector((s) => s.notifications.unreadCount);
  const notificationError = useSelector((s) => s.notifications.error);

  const dispatch = useDispatch();

  useEffect(R.pipe(getToken, decode, R.path(['user']), setUser), []);

  useEffect(() => {
    dispatch(getNotifications());

    const interval = setInterval(
      () => dispatch(updateNotifications()),
      config.activityUpdateInterval
    );

    return () => clearInterval(interval);
  }, [dispatch]);

  return (
    <Header>
      <LogoArea>
        <NavLink to="/">
          <Logo name="BACKSTAGE" />
        </NavLink>
      </LogoArea>
      <NavigationArea>
        <Navigation>
          <NavItem forwardedAs={NavLink} to="/search">
            Search
          </NavItem>
          <NavItem forwardedAs={NavLink} to="/contact-sheets">
            Contact Sheets
          </NavItem>
          <NavItem forwardedAs={NavLink} to="/activity-feed">
            Activity Feed
          </NavItem>
        </Navigation>
      </NavigationArea>
      <UserDetails>
        <NavLink to="/notifications">
          <BellContainer>
            <Icon name="bell" size={22} />
            {notificationError && <ImageCount>Error</ImageCount>}
            {!notificationError && !!unreadCount && (
              <ImageCount>{unreadCount}</ImageCount>
            )}
          </BellContainer>
        </NavLink>
        <Icon name="account" size={22} />
        <UserName>{user.userName}</UserName>
      </UserDetails>
    </Header>
  );
};

export default AppHeader;
