import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Icon from '@yic/icon';
import styled from 'styled-components';
import OutsideClickHandler from 'react-outside-click-handler';
import * as clipboard from 'clipboard-polyfill';

const Wrapper = styled.div`
  position: relative;
  display: inline-block;
`;

const TooltipButton = styled.button`
  margin-left: 1rem;
  border-radius: 0.2rem;
  border: 0;
  height: 2.2rem;
  width: 2.2rem;
  background-color: ${({ active }) => (active ? '#00000014' : '#0000000a')};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  :hover {
    background-color: #00000014;
  }
`;

const DropdownContainer = styled.div`
  position: absolute;
  right: 0;
  z-index: 10;
  top: 3.2rem;
`;

const Dropdown = styled.ul`
  position: relative;
  background-color: #f7f7f7;
  margin: 0.2rem 0 0;
  padding: 0;
  border-radius: 0.2rem;
  box-shadow: 0 0 3rem 0 rgba(0, 0, 0, 0.2);
  list-style-type: none;
  font-size: 1.15rem;

  &::before {
    border: solid transparent;
    content: ' ';
    position: absolute;
    pointer-events: none;
    border-bottom-color: #f7f7f7;
    border-width: 1.1rem;
    right: 0rem;
    top: -2.1rem;
  }

  button {
    width: 100%;
  }
`;

const StyledListItem = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1rem;
  cursor: pointer;

  :hover {
    background-color: #ececec;
  }
`;

const Text = styled.div`
  margin-left: 0.8rem;
  white-space: nowrap;
`;

const getImageData = (data, withDetails) => {
  const { productId, shotType, optionNumber, url } = data;

  const imageName = `${productId} - ${shotType.toUpperCase()}${
    optionNumber ? ` - Option ${optionNumber}` : ''
  }`;

  return withDetails
    ? `
    <div>
      <div style="font-size:13px">${imageName}</div>
      <img height="300" src="${url}">
    </div>
  `
    : `<div><img height="300" src="${url}"></div>`;
};

const ImageTooltip = ({ data }) => {
  const [isVisible, setIsVisible] = useState(false);

  const onOptionClick = (withDetails) => () => {
    const dt = new clipboard.DT();

    dt.setData('text/html', getImageData(data, withDetails));

    clipboard.write(dt);

    setIsVisible(false);
  };

  return (
    <OutsideClickHandler
      onOutsideClick={() => setIsVisible((c) => !c)}
      disabled={!isVisible}
    >
      <Wrapper>
        <TooltipButton
          onClick={() => setIsVisible((c) => !c)}
          active={isVisible}
          aria-label="copy-image-options"
        >
          <Icon name="more" colour="black" size={10} />
        </TooltipButton>
        {isVisible && (
          <DropdownContainer>
            <Dropdown>
              <StyledListItem onClick={onOptionClick(false)}>
                <Icon name="password" size={14} />
                <Text>Copy image</Text>
              </StyledListItem>
              <StyledListItem onClick={onOptionClick(true)}>
                <Icon name="plus" size={14} />
                <Text>Copy image and info</Text>
              </StyledListItem>
            </Dropdown>
          </DropdownContainer>
        )}
      </Wrapper>
    </OutsideClickHandler>
  );
};

ImageTooltip.propTypes = {
  data: PropTypes.shape({
    productId: PropTypes.string.isRequired,
    shotType: PropTypes.string.isRequired,
    optionNumber: PropTypes.number,
    url: PropTypes.string.isRequired
  }).isRequired
};

ImageTooltip.defaultProps = {};

export default ImageTooltip;
