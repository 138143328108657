import React, { forwardRef, useState, useEffect, Children } from 'react';
import PropTypes from 'prop-types';
import Navigation from './Navigation';

const clamp = (num, min, max) => Math.min(Math.max(num, min), max);

const Tabs = forwardRef(
  ({ children, activeTab, size, onChange, ...props }, ref) => {
    const tabCount = Children.count(children);
    const [active, setActive] = useState(clamp(activeTab - 1, 0, tabCount));

    useEffect(() => {
      if (onChange) {
        onChange(active);
      }
    }, [active, onChange]);

    return (
      <div {...props} ref={ref}>
        <Navigation
          activeTab={active}
          size={size}
          onClick={(i) => setActive(i)}
          onChange={onChange}
          tabCount={tabCount}
        >
          {children}
        </Navigation>
        {Children.map(children, (Child, i) => (
          <Child.type {...Child.props} visible={active === i} />
        ))}
      </div>
    );
  }
);

Tabs.propTypes = {
  children: PropTypes.node.isRequired,
  activeTab: PropTypes.number,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  onChange: PropTypes.func
};

Tabs.defaultProps = {
  activeTab: 1,
  size: 'large',
  onChange: undefined
};

export default Tabs;
