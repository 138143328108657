import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Icon from '@yic/icon';
import moment from 'moment';
import Loader from '@yic/loader';
import Img from './Img';
import ImageTooltip from './ImageTooltip';
import ImageComments from './ImageComments';
import {
  getImageDetails,
  addComment,
  setImageStatus
} from '../../services/data';
import ErrorMessage from '../errors/ErrorMessage';
import { Tab, Tabs } from '../tabs';
import StatusButtons from '../StatusButtons';

const Container = styled.div`
  padding: 1.5rem 0 2.5rem;
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-row-gap: 2rem;

  @media only screen and (max-width: 1200px) {
    grid-template-columns: 1fr 1fr;
  }

  @media only screen and (max-width: 800px) {
    grid-template-columns: 1fr;
  }
`;

const StatusButtonsContainer = styled.div`
  grid-column: 1 / -1;
`;

const CurrentVersionContainer = styled.div`
  margin-right: 1.52rem;

  @media only screen and (max-width: 1200px) {
    margin-right: 1.54rem;
  }

  @media only screen and (max-width: 800px) {
    margin: 0;
  }
`;

const CurrentVersionTitle = styled.div`
  font-family: Georgia, sans-serif;
  font-size: 1.8rem;
  margin: 1rem 0 1rem;
  padding-bottom: 1.2rem;
  border-bottom: 0.1rem solid #f0f0f0;
  user-select: none;
  display: flex;
`;

const LargeCheck = styled.span`
  margin-right: 0.6rem;
  align-self: center;
`;

const TabsContainer = styled.div`
  padding-left: 1.6rem;

  @media only screen and (max-width: 800px) {
    padding: 0;
    margin-top: 1rem;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;

  div {
    padding: 0;
  }

  :hover {
    opacity: 1;
  }
`;

const ImageGrid = styled.div`
  margin-top: 1rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  @media only screen and (max-width: 1200px) {
    grid-template-columns: 1fr;
  }

  grid-gap: 1.5rem;
`;

const ImageHeader = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  margin: 1rem 0 0.5rem;
`;

const ImageHeaderItem = styled.div`
  display: flex;
  align-items: flex-end;
`;

const IconContainer = styled.div`
  margin: 0 0.3rem 0.15rem 0;
`;

const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 4rem 0;

  > div {
    height: 3.5rem;
    width: 3.5rem;
  }

  > p {
    margin: 0.8rem 0;
    font-family: Arial, sans-serif;
    font-size: 1.2rem;
    font-style: italic;
  }
`;

const ErrorWrapper = styled.div`
  padding: 0 1rem;
`;

const ImageDetails = ({
  image,
  selectedTab,
  close,
  showImageStatusButtons
}) => {
  const [commentInputVal, setCommentInputVal] = useState();
  const [isFetchingDetails, setIsFetchingDetails] = useState(true);
  const [pageError, setPageError] = useState();
  const [details, setDetails] = useState({
    options: [],
    versions: [],
    comments: []
  });

  const mountedRef = useRef(null);

  const { filename, business, productId, shotType } = image;

  useEffect(() => {
    const isMounted = mountedRef.current;
    const handleEscapeKey = ({ keyCode }) => keyCode === 27 && close();

    if (isMounted) {
      document.addEventListener('keydown', handleEscapeKey);
    }

    return () =>
      isMounted && document.removeEventListener('keydown', handleEscapeKey);
  }, [close]);

  useEffect(() => {
    const isMounted = mountedRef.current;

    getImageDetails({
      filename,
      business,
      productId,
      shotType
    })
      .then((res) => {
        if (isMounted) {
          setDetails(res);
          setIsFetchingDetails(false);
        }
      })
      .catch((err) => {
        if (isMounted) {
          setIsFetchingDetails(false);
          setPageError(err);
        }
      });
  }, [business, filename, productId, shotType]);

  const submitComment = async (text) => {
    const res = await addComment({ filename, business, text });
    setDetails((curr) => ({ ...curr, comments: res.comments }));
  };

  const updateImageStatus = async (approvalStatus) => {
    await setImageStatus({ approvalStatus, images: [{ filename, business }] });
    setDetails({ ...details, approvalStatus });
  };

  const { options, versions, comments } = details;

  return (
    <Container ref={mountedRef}>
      <CurrentVersionContainer>
        <CurrentVersionTitle>
          <LargeCheck>
            <Icon name="confirmation" size={16} colour="green" />
          </LargeCheck>
          Current Version
        </CurrentVersionTitle>

        <ImageContainer>
          <ImageHeader>
            <ImageHeaderItem>
              <ImageTooltip data={image} />
            </ImageHeaderItem>
            {details.lastImageUpdate && (
              <ImageHeaderItem>
                <IconContainer>
                  <Icon name="clock" size={11} />
                </IconContainer>
                <i>Uploaded {moment(details.lastImageUpdate).fromNow()}</i>
              </ImageHeaderItem>
            )}
          </ImageHeader>
          <Img data={image} />
        </ImageContainer>
      </CurrentVersionContainer>

      <TabsContainer>
        <Tabs size="medium" activeTab={selectedTab}>
          <Tab
            title="Options"
            count={options.length}
            disabled={!options.length}
          >
            {!isFetchingDetails && (
              <ImageGrid>
                {options.map((option) => (
                  <ImageContainer key={`${option.filename}-option-container`}>
                    <ImageHeader>
                      <ImageHeaderItem>
                        <ImageTooltip data={option} />
                      </ImageHeaderItem>

                      <ImageHeaderItem>
                        <IconContainer>
                          <Icon name="plus" size={11} />
                        </IconContainer>
                        <i>Option {option.optionNumber}</i>
                      </ImageHeaderItem>
                    </ImageHeader>
                    <Img data={option} />
                  </ImageContainer>
                ))}
              </ImageGrid>
            )}
          </Tab>

          <Tab title="Comments" count={comments.length}>
            {!isFetchingDetails && (
              <ImageComments
                inputValue={commentInputVal}
                comments={comments}
                submitComment={submitComment}
                setCommentInputVal={setCommentInputVal}
              />
            )}
          </Tab>

          <Tab
            title="History"
            count={versions.length}
            disabled={!versions.length}
          >
            {!isFetchingDetails && (
              <ImageGrid>
                {versions.map((version) => (
                  <ImageContainer
                    key={`${version.filename}-${version.uploadTime}`}
                  >
                    <ImageHeader>
                      <ImageHeaderItem>
                        <ImageTooltip data={version} />
                      </ImageHeaderItem>

                      <ImageHeaderItem>
                        <IconContainer>
                          <Icon name="clock" size={11} />
                        </IconContainer>
                        <i>Uploaded {moment(version.uploadTime).fromNow()}</i>
                      </ImageHeaderItem>
                    </ImageHeader>
                    <Img data={version} />
                  </ImageContainer>
                ))}
              </ImageGrid>
            )}
          </Tab>
        </Tabs>

        {isFetchingDetails && !pageError && (
          <LoaderContainer>
            <div>
              <Loader size={15} />
            </div>
            <p>Loading, please wait...</p>
          </LoaderContainer>
        )}
        {pageError && (
          <ErrorWrapper>
            {pageError.code}
            <ErrorMessage code={pageError.code} message={pageError.message} />
          </ErrorWrapper>
        )}
      </TabsContainer>
      {showImageStatusButtons && (
        <StatusButtonsContainer>
          <StatusButtons
            productId={productId}
            filename={filename}
            business={business}
            currentStatus={details.approvalStatus}
            updateImageStatus={updateImageStatus}
          />
        </StatusButtonsContainer>
      )}
    </Container>
  );
};

ImageDetails.propTypes = {
  image: PropTypes.shape({
    filename: PropTypes.string,
    business: PropTypes.string,
    productId: PropTypes.string,
    shotType: PropTypes.string
  }).isRequired,
  selectedTab: PropTypes.oneOf([1, 2, 3, undefined]).isRequired,
  close: PropTypes.func.isRequired,
  showImageStatusButtons: PropTypes.bool
};

ImageDetails.defaultProps = {
  showImageStatusButtons: false
};

export default ImageDetails;
