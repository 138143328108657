import * as R from 'ramda';
import decode from 'jwt-decode';
import { getToken } from '../services/authentication';

const getUserPermissionsFromToken = R.pipe(
  getToken,
  decode,
  R.pathOr([], ['user', 'permissions']),
  R.tap(console.log)
);

const hasAtLeastOneOf = R.curry(R.pipe(R.intersection, R.length, Boolean));

export const isUserAllowedAccess = R.pipe(
  getUserPermissionsFromToken,
  hasAtLeastOneOf([
    'BACKSTAGE_READER',
    'BACKSTAGE_CONTRIBUTOR',
    'BACKSTAGE_APPROVER'
  ])
);

export const getUserActionsPermissions = R.pipe(
  getUserPermissionsFromToken,
  R.applySpec({
    canApprove: R.includes('BACKSTAGE_APPROVER'),
    canComment: hasAtLeastOneOf(['BACKSTAGE_APPROVER', 'BACKSTAGE_CONTRIBUTOR'])
  })
);
