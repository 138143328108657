import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Icon from '@yic/icon';
import AsyncButton from './AsyncButton';
import { getUserActionsPermissions } from '../utils/permissions';

const ButtonsContainer = styled.div`
  display: flex;
  margin-top: 2rem;
  justify-content: center;
`;

const StatusButton = styled(AsyncButton)`
  && {
    min-width: 11.2rem;
  }
  & + & {
    margin-left: 1.6rem;
  }
`;

const STATUS_REMOVAL_TEXT =
  'Remove approval status from all images for this product';

const BulkStatusButtons = ({
  bulkUpdateStatus,
  bulkApprovalStatus,
  className
}) => {
  const [loadingType, setLoadingType] = useState(null);

  const { canApprove } = getUserActionsPermissions();

  const onBtnClick = (type) => async () => {
    setLoadingType(type);
    await bulkUpdateStatus(type);
    setLoadingType(null);
  };

  return canApprove ? (
    <ButtonsContainer className={className}>
      <StatusButton
        selected={bulkApprovalStatus === 'approval'}
        size="small"
        onClick={onBtnClick('approval')}
        isLoading={loadingType === 'approval'}
        title={
          bulkApprovalStatus === 'approval'
            ? STATUS_REMOVAL_TEXT
            : 'Approve all images for this product'
        }
      >
        <Icon name="confirmation" colour="green" />
        {bulkApprovalStatus === 'approval' ? 'Approved All' : 'Approve All'}
      </StatusButton>
      <StatusButton
        selected={bulkApprovalStatus === 'hold'}
        size="small"
        onClick={onBtnClick('hold')}
        isLoading={loadingType === 'hold'}
        title={
          bulkApprovalStatus === 'hold'
            ? STATUS_REMOVAL_TEXT
            : 'Hold all images for this product'
        }
      >
        <Icon name="warning" colour="darkorange" />
        {bulkApprovalStatus === 'hold' ? 'Held All' : 'Hold All'}
      </StatusButton>
      <StatusButton
        selected={bulkApprovalStatus === 'rejection'}
        size="small"
        onClick={onBtnClick('rejection')}
        isLoading={loadingType === 'rejection'}
        title={
          bulkApprovalStatus === 'rejection'
            ? STATUS_REMOVAL_TEXT
            : 'Reject all images for this product'
        }
      >
        <Icon name="error" colour="#B60218" />
        {bulkApprovalStatus === 'rejection' ? 'Rejected All' : 'Reject All'}
      </StatusButton>
    </ButtonsContainer>
  ) : null;
};

BulkStatusButtons.propTypes = {
  bulkUpdateStatus: PropTypes.func.isRequired,
  className: PropTypes.string,
  bulkApprovalStatus: PropTypes.string
};

BulkStatusButtons.defaultProps = {
  className: '',
  bulkApprovalStatus: null
};

export default BulkStatusButtons;
