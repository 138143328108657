import qs from 'querystring';
import * as R from 'ramda';

// Horrible workaround to prevent the 'Date' object from
// changing the day in the US timezone when a new 'Date'
// is created from the 'date' query string.
export const parseDate = (date) => new Date(`${date}T12:00:00`);

const parseUrlQuery = R.pipe(R.tail, qs.parse, R.omit(['token']));

const ifNotEmpty = R.ifElse(R.isEmpty, R.always(false), R.__);

export const parseSetUrlQuery = R.pipe(
  parseUrlQuery,
  R.pick([
    'date',
    'business',
    'location',
    'photographer',
    'setType',
    'setTime',
    'designer',
    'imageType'
  ]),
  R.reject(R.isEmpty),
  ifNotEmpty(R.evolve({ date: parseDate }))
);

export const parseSearchUrlQuery = R.pipe(
  parseUrlQuery,
  R.pick(['productIds']),
  ifNotEmpty(R.pipe(R.prop('productIds'), R.split(','), R.uniq, R.join(', ')))
);

export default {
  parseDate,
  parseSetUrlQuery,
  parseSearchUrlQuery
};
