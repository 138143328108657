import React from 'react';
import PropTypes from 'prop-types';
import ContactSheets from './contact-sheets/ContactSheets';
import ProductSearch from './product-search/ProductSearch';
import Notifications from './notifications/Notifications';
import NotificationsWs from './notifications/NotificationsWs';
import ActivityFeed from './activity-feed/ActivityFeed';

const Main = (props) => {
  const { pathname } = props.location;

  return (
    <>
      {['/search', '/'].includes(pathname) && <ProductSearch />}
      {pathname === '/contact-sheets' && <ContactSheets />}
      {pathname === '/activity-feed' && <ActivityFeed />}
      {pathname === '/notifications' && <Notifications />}
      {pathname === '/notifications-ws' && <NotificationsWs />}
    </>
  );
};

Main.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string
  })
};

Main.defaultProps = {
  location: { pathname: 'search' }
};

export default Main;
