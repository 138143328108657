import { useState, useReducer, useEffect } from 'react';
import * as R from 'ramda';
import { getOptions } from '../services/data';

const filterNames = {
  business: 'Business',
  location: 'Location',
  setType: 'Set Type',
  setTime: 'Set Time',
  photographer: 'Photographer',
  designer: 'Designer'
};

const formatOptions = (options) =>
  Object.keys(options).reduce((accum, key) => {
    const filterPrompt = { id: null, name: `Select ${filterNames[key]}` };

    switch (key) {
      case 'business':
      case 'setTime':
      case 'imageType':
        return {
          ...accum,
          [key]: options[key]
        };
      default:
        return {
          ...accum,
          [key]: [filterPrompt, ...options[key]]
        };
    }
  }, {});

const selectionReducer = (state, action) => {
  switch (action.type) {
    case 'set': {
      return {
        ...state,
        [action.filter]: action.value
      };
    }
    case 'clear': {
      const newState = { ...state };
      delete newState[action.filter];
      return newState;
    }
    case 'overwriteAll':
      return action.filters;
    case 'resetOptional':
      return {
        date: state.date,
        business: state.business
      };
    default:
      throw new Error(`Unrecognised action type: ${action.type}`);
  }
};

const useFilters = (defaultSelection, filters) => {
  const [isFetchingOptions, setIsFetchingOptions] = useState(true);
  const [optionsFetchError, setOptionsFetchError] = useState(null);
  const [options, setOptions] = useState();
  const [selection, setSelection] = useReducer(
    selectionReducer,
    defaultSelection
  );

  useEffect(() => {
    getOptions(filters && { field: filters })
      .then(R.pipe(formatOptions, setOptions))
      .catch(setOptionsFetchError)
      .finally(R.pipe(R.F, setIsFetchingOptions));
  }, [filters]);

  useEffect(() => {
    const { designer, business, photographer, location } = selection;

    if (designer && business) {
      const match = options.designer.some(
        (o) => o.id === designer && o.businesses.includes(business)
      );

      if (!match) setSelection({ type: 'clear', filter: 'designer' });
    }

    if (photographer && location) {
      const match = options.photographer.some(
        (o) => o.id === photographer && o.location === location
      );

      if (!match) setSelection({ type: 'clear', filter: 'photographer' });
    }
  }, [options, selection]);

  const getSelectedValue = (filter) =>
    options[filter].find((o) => o.id === selection[filter]) ||
    options[filter][0];

  const selectionLength = R.pipe(
    R.values,
    R.filter(Boolean),
    R.length
  )(selection);

  return {
    isFetchingOptions,
    optionsFetchError,
    options,
    selection,
    setSelection,
    selectionLength,
    getSelectedValue
  };
};

export default useFilters;
