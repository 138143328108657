import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from '@yic/button';
import Icon from '@yic/icon';
import moment from 'moment';
import { getUserActionsPermissions } from '../../utils/permissions';

const Container = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  grid-column: 1/-1;
`;

const Comments = styled.div`
  margin: 1rem 0;
  border: 1px solid #f2f0f0;
  border-radius: 0.2rem;
  font-size: 1.2rem;

  > div:nth-child(odd) {
    background-color: #f7f7f7;
  }
`;

const CommentContainer = styled.div`
  padding: 1rem 1rem 0.8rem;
  background-color: #fdfdfd;
`;

const CommentUser = styled.div`
  font-weight: bold;
  font-size: 1.2rem;
`;

const CommentText = styled.div`
  font-size: 1.3rem;
  border-radius: 0.2rem;
  padding: 0.4rem 0rem 0.6rem;
  line-height: 1.8rem;
  white-space: pre;
`;

const CommentTime = styled.div`
  color: grey;
  font-size: 1.1rem;
  font-style: italic;
`;

const NoComments = styled.div`
  font-size: 1.2rem;
  text-align: left;
  margin: 1rem 0;
  font-style: italic;
  background-color: #f4f4f4;
  padding: 1.5rem;
`;

const SubmitFailedMessage = styled.div`
  background-color: #fbe8e8;
  border-radius: 0.2rem;
  color: #b60218;
  margin: 1rem 0;
  padding: 2rem 1.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 1.4rem;

  > div {
    margin-left: 1rem;
  }
`;

const InputForm = styled.form`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
`;

const Input = styled.textarea`
  min-height: 6.5rem;
  border-radius: 0.2rem;
  padding: 0.5rem;
  resize: vertical;
  font-family: sans-serif;
  font-size: 1.3rem;
`;

const SubmitButton = styled(Button.Primary)`
  margin-top: 1.5rem;
  max-height: 3.8rem;
  height: 3.8rem;
  max-width: 20rem;
  min-width: 10rem;
`;

const ImageComments = ({
  comments,
  inputValue,
  submitComment,
  setCommentInputVal
}) => {
  const [status, setStatus] = useState(false);

  const onSubmitClick = async () => {
    setStatus('fetching');

    try {
      await submitComment(inputValue.trim());

      setCommentInputVal();
      setStatus('idle');
    } catch (err) {
      setStatus('error');
    }
  };

  const { canComment } = getUserActionsPermissions();

  return (
    <Container>
      {comments.length ? (
        <Comments>
          {comments.map(({ userName, text, time }) => (
            <CommentContainer key={`${userName}-${time}`}>
              <CommentUser>{userName}</CommentUser>
              <CommentText>{text}</CommentText>
              <CommentTime>{moment(time).fromNow()}</CommentTime>
            </CommentContainer>
          ))}
        </Comments>
      ) : (
        <NoComments>No comments</NoComments>
      )}

      {status === 'error' && (
        <SubmitFailedMessage>
          <Icon name="warning" colour="#B60218" />
          <div>Error adding comment!</div>
        </SubmitFailedMessage>
      )}

      {canComment && (
        <InputForm>
          <Input
            value={inputValue}
            onChange={(e) => setCommentInputVal(e.target.value)}
          />
          <SubmitButton
            size="small"
            onClick={onSubmitClick}
            disabled={!inputValue.trim() || status === 'fetching'}
          >
            Submit Comment
          </SubmitButton>
        </InputForm>
      )}
    </Container>
  );
};

ImageComments.propTypes = {
  comments: PropTypes.arrayOf(
    PropTypes.shape({
      userName: PropTypes.string.isRequired,
      text: PropTypes.string,
      time: PropTypes.number
    })
  ),
  inputValue: PropTypes.string,
  setCommentInputVal: PropTypes.func.isRequired,
  submitComment: PropTypes.func.isRequired
};

ImageComments.defaultProps = {
  inputValue: '',
  comments: []
};

export default ImageComments;
