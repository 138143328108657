import SSO from '@ynap/mit-sso';
import config from '../config';

// MIT-1785 - Investigate user auth in prod for sts adfs host
const urlParams = new URLSearchParams(window.location.search);

const ssoHost = urlParams.has('auth')
  ? `${config.ssoHost}${window.location.search}`
  : config.ssoHost;

const sso = new SSO(ssoHost);

const saveNewToken = () => {
  const url = new URL(window.location);
  const newToken = url.searchParams.get('token');

  if (newToken) {
    url.searchParams.delete('token');
    sessionStorage.setItem('token', newToken);
    window.history.replaceState(null, document.title, decodeURIComponent(url));
  }
};

const login = () =>
  sso
    .getLoginUrl(window.location.href)
    .then((url) => window.location.assign(url));

const authenticate = () => {
  saveNewToken();

  const token = sessionStorage.getItem('token');

  return !!token;
};

const getToken = () => window.sessionStorage.getItem('token');

export { login, authenticate, getToken };
