import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Icon from '@yic/icon';

const Container = styled.div`
  margin-top: 3rem;
  letter-spacing: 0.02rem;
  line-height: 1.5;
  font-size: 1.6rem;
  font-family: Arial, sans-serif;
`;

const Ul = styled.ul`
  list-style: none;
  padding: 0;

  svg {
    display: inline-block;
    top: 0.5rem;
    position: relative;
  }

  p {
    display: inline-block;
    margin-top: 0;
    margin-left: 1.1rem;
  }
`;

const Anchor = styled.a`
  text-decoration: underline;

  &:hover {
    color: #000;
  }
`;

const BigHeading = styled.div`
  font-family: Georgia, sans-serif;
  font-size: 2.5rem;
  margin: 0 0 2.5rem 0.2rem;
`;

const NoResults = ({ heading, suggestions }) => (
  <Container id="no-results-container">
    <BigHeading>{heading}</BigHeading>
    {suggestions.length && (
      <>
        <p>
          <strong>Suggestions:</strong>
        </p>
        <ul>
          {suggestions.map((suggestion) => (
            <li key={suggestion}>{suggestion}</li>
          ))}
        </ul>
      </>
    )}
    <p>
      If you continue to have problems, please call or raise a ticket with
      Service Desk:
    </p>
    <Ul>
      <li>
        <Icon name="Mobile" size={24} colour="#010101" />
        <p>Extension: 4700</p>
      </li>
      <li>
        <Icon name="External-link" size={24} colour="#010101" />
        <p>
          <Anchor
            href="https://ynap.service-now.com/sp?id=sc_cat_item&sys_id=bf1adf431b004410ffbbea08bd4bcbfa&sysparm_category=fffc79511b9f7380d39ded3fad4bcb40"
            target="_blank"
          >
            Service Now - Report Problem
          </Anchor>
        </p>
      </li>
    </Ul>
  </Container>
);

NoResults.propTypes = {
  heading: PropTypes.string.isRequired,
  suggestions: PropTypes.arrayOf(PropTypes.string)
};

NoResults.defaultProps = {
  suggestions: []
};

export default NoResults;
