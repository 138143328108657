/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from '@yic/button';
import Loader from '@yic/loader';

const InActiveStatusButton = styled(Button.Secondary)`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const ActiveStatusButton = styled(Button.Primary)`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const LoaderContainer = styled.div`
  height: 1.5rem;
  width: 1.5rem;
  margin-right: 0.3rem;
`;

const AsyncButton = ({ isLoading, children, selected, ...rest }) => {
  const StatusButton = selected ? ActiveStatusButton : InActiveStatusButton;

  return (
    <StatusButton {...rest}>
      {isLoading ? (
        <LoaderContainer>
          {selected ? <Loader color="white" /> : <Loader />}
        </LoaderContainer>
      ) : (
        children
      )}
    </StatusButton>
  );
};

AsyncButton.propTypes = {
  isLoading: PropTypes.bool,
  selected: PropTypes.bool,
  children: PropTypes.node.isRequired
};

AsyncButton.defaultProps = {
  isLoading: false,
  selected: false
};

export default AsyncButton;
