import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { Sprite } from '@yic/icon';
import { Provider } from 'react-redux';
import App from './App';
import history from './services/history';
import './styles';
import { authenticate, login } from './services/authentication';
import store from './redux/store';

if (authenticate()) {
  ReactDOM.render(
    <Provider store={store}>
      <Router history={history}>
        <Sprite />
        <App />
      </Router>
    </Provider>,
    document.getElementById('root')
  );
} else {
  login().catch(console.error);
}
