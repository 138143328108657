import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Main from './components/Main';

const Routes = () => (
  <Switch>
    <Route exact path="/" key="/" component={Main} />
    <Route path="/search" exact key="/search" component={Main} />
    <Route
      path="/contact-sheets"
      exact
      key="/contact-sheets"
      component={Main}
    />
    <Route path="/activity-feed" exact key="/activity-feed" component={Main} />
    <Route path="/notifications" exact key="/notifications" component={Main} />
    <Route
      path="/notifications-ws"
      exact
      key="/notifications-ws"
      component={Main}
    />
    <Route path="*" />
  </Switch>
);

export default Routes;
