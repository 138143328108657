import request from 'request-promise';
import qs from 'querystring';
import config from '../config';
import { getToken, login } from './authentication';

const errorHandler = (err) => {
  if (err.statusCode === 401) return login();
  throw err;
};

const get = (route) => (params) => {
  const query = params ? `?${qs.stringify(params)}` : '';

  return request({
    method: 'GET',
    uri: `${config.apiUrl}/${route}${query}`,
    json: true,
    headers: {
      Authorization: getToken()
    }
  }).catch(errorHandler);
};

const put = (route) => (body) =>
  request({
    method: 'PUT',
    uri: `${config.apiUrl}/${route}`,
    json: true,
    headers: {
      Authorization: getToken()
    },
    body
  }).catch(errorHandler);

export const getSets = get('search/set');
export const searchImages = get('search/product');
export const getOptions = get('set-options');
export const getImageDetails = get('image-details');
export const getUserNotifications = get('activity/user');
export const getGlobalActivity = get('activity/all');
export const markNotificationSeen = put('activity/seen');
export const addComment = put('add-comment');
export const setImageStatus = put('image-status');
