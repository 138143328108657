import React, { useEffect, useState, useCallback, useReducer } from 'react';
import FormControl from '@yic/form-control';
import Toggle from '@yic/toggle';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import Loader from '@yic/loader';
import * as R from 'ramda';
import Notification from '../notifications/Notification';
import Checkbox from '../Checkbox';
import useFilters from '../../hooks/useFilters';
import ErrorMessage from '../errors/ErrorMessage';
import { getGlobalActivity } from '../../services/data';
import NoResults from '../errors/NoResults';
import config from '../../config';

const Container = styled.div`
  display: grid;
  margin: 2.5rem 2.9rem;
  grid-row-gap: 1rem;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  > form {
    width: 100%;
  }
`;

const FormGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(16rem, 19rem));
  grid-gap: 1.8rem 2.4rem;
  width: 100%;
  margin-right: 4rem;
  flex-grow: 1;
`;

const FilterPairContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  p {
    margin: 0 0 0.9rem 0;
    font-size: 1.2rem;

    @media only screen and (max-width: 767px) {
      font-size: 1.4rem;
    }
  }

  > div {
    display: flex;
    height: 100%;
    align-items: center;
    margin-left: 0.1rem;
  }

  label:nth-of-type(2) {
    margin-left: 1rem;
  }
`;

const CheckboxWrapper = styled.div`
  margin-right: 2.8rem;
`;

const LargeLoaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    margin: 0.2rem 0;
    font-family: Arial, sans-serif;
    font-size: 1.3rem;
    font-style: italic;
  }

  > div {
    margin: 1rem auto;
    height: 4rem;
    width: 4rem;
  }
`;

const ToggleContainer = styled.div`
  margin-left: 2rem;
  width: 17.4rem;
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  flex-direction: row;
  align-self: flex-start;
  justify-content: flex-end;
  align-items: center;
`;

const ToggleTitle = styled.div`
  margin-right: 0.8rem;
  white-space: nowrap;
`;

const SelectedFiltersContainer = styled.div`
  margin-top: 1.5rem;
  display: flex;
  flex-direction: row;
  white-space: nowrap;
`;

const FilterMessage = styled.div`
  color: ${({ active }) => (active ? '#000' : '#9B9B9B')};
  font-size: 1.4rem;
  line-height: 2.3rem;
  display: inline-block;
`;

const ClearFiltersButton = styled.button`
  background: none;
  border: none;
  color: #000;
  font-size: 1.4rem;
  line-height: 1.8rem;
  margin-left: 2.5rem;
  cursor: pointer;
  padding: 0;
  height: 2rem;
  border-bottom: 1px solid #000;

  &:hover,
  &:focus {
    border-bottom: 1px solid transparent;
  }
`;

const MediumLoaderWrapper = styled.div`
  margin: 4rem auto;
  height: 6rem;
  width: 6rem;
`;

const SmallLoaderWrapper = styled.div`
  margin-right: 1rem;
  height: 1.5rem;
  width: 1.5rem;
`;

const EventsContainer = styled.div`
  display: grid;
  margin: 2.5rem 0;
  grid-row-gap: 1rem;
`;

const getRangeFilterTimestamp = (rangeValue) =>
  Date.now() - 1000 * 60 * 60 * rangeValue;

const reducer = (state, action) => {
  switch (action.type) {
    case 'setActivity': {
      return {
        ...state,
        activity: action.payload,
        status: 'idle',
        error: null
      };
    }
    case 'updateActivity': {
      return {
        ...state,
        activity: [...action.payload, ...state.activity],
        status: 'idle',
        error: null
      };
    }
    case 'fetchingActivity': {
      return {
        ...state,
        status: 'fetching'
      };
    }
    case 'updatingActivity': {
      return {
        ...state,
        status: 'updating'
      };
    }
    case 'error': {
      return {
        ...state,
        status: 'error',
        error: action.payload
      };
    }
    case 'toggleAutoUpdate': {
      return {
        ...state,
        autoUpdate: !state.autoUpdate
      };
    }
    default: {
      throw new Error();
    }
  }
};

const defaultState = {
  activity: [],
  status: 'idle',
  autoUpdate: true
};

const filtersToUse = ['business', 'location', 'setType'];

const ActivityFeed = () => {
  const [state, dispatch] = useReducer(reducer, defaultState);
  const [rangeValue, setRangeValue] = useState(1);
  const {
    isFetchingOptions,
    optionsFetchError,
    options,
    selection,
    setSelection,
    selectionLength,
    getSelectedValue
  } = useFilters({}, filtersToUse);

  const { activity, status, error, autoUpdate } = state;

  useEffect(() => {
    dispatch({ type: 'error', payload: optionsFetchError });
  }, [optionsFetchError]);

  useEffect(() => {
    dispatch({ type: 'fetchingActivity' });

    const queryParms = {
      ...selection,
      since: getRangeFilterTimestamp(rangeValue)
    };

    getGlobalActivity(queryParms)
      .then((payload) => dispatch({ type: 'setActivity', payload }))
      .catch((payload) => dispatch({ type: 'error', payload }));
  }, [rangeValue, selection]);

  const updateActivity = useCallback(() => {
    dispatch({ type: 'updatingActivity' });

    const latestTimestamp = activity.length
      ? R.path([0, 'timestamp'], activity)
      : getRangeFilterTimestamp(rangeValue);

    const queryParms = { ...selection, since: latestTimestamp };

    getGlobalActivity(queryParms)
      .then((payload) => dispatch({ type: 'updateActivity', payload }))
      .catch((payload) => dispatch({ type: 'error', payload }));
  }, [activity, rangeValue, selection]);

  useEffect(() => {
    const interval =
      autoUpdate && setInterval(updateActivity, config.activityUpdateInterval);

    return () => interval && clearInterval(interval);
  }, [updateActivity, autoUpdate]);

  const resetFilters = () => {
    setSelection({ type: 'overwriteAll', filters: {} });
  };

  const onDropdownChange = (filter) => ({ target }) => {
    setSelection({ type: 'set', filter, value: target.id });
  };

  const onCheckboxChange = (filter) => (value) =>
    selection[filter] === value
      ? setSelection({ type: 'clear', filter })
      : setSelection({ type: 'set', filter, value });

  const onAutoUpdateChange = () => {
    if (!autoUpdate) updateActivity();
    dispatch({ type: 'toggleAutoUpdate' });
  };

  return (
    <Container>
      <Helmet>
        <title>Activity Feed - Backstage</title>
      </Helmet>

      {isFetchingOptions && (
        <LargeLoaderWrapper>
          <div>
            <Loader />
          </div>
          <p>Updating events, please wait...</p>
        </LargeLoaderWrapper>
      )}

      {status === 'error' && (
        <ErrorMessage
          type="Activity Feed"
          code={error.code}
          message={error.message}
        />
      )}

      {!isFetchingOptions && status !== 'error' && (
        <>
          <Header>
            <form>
              <FormGrid>
                <FilterPairContainer>
                  <p>Business</p>
                  <div>
                    {options.business.map(({ name }) => (
                      <CheckboxWrapper key={`${name}-business`}>
                        <Checkbox
                          onClick={onCheckboxChange('business')}
                          checked={selection.business === name}
                          label={name}
                          disabled={status === 'fetching'}
                        />
                      </CheckboxWrapper>
                    ))}
                  </div>
                </FilterPairContainer>

                <FormControl.SimpleDropdown
                  key={`${selection.location}-location`}
                  label="Location"
                  name="Location"
                  size="small"
                  value={getSelectedValue('location')}
                  options={options.location}
                  onChange={onDropdownChange('location')}
                  disabled={status === 'fetching'}
                />
                <FormControl.SimpleDropdown
                  key={`${selection.setType}-setType`}
                  label="Set Type"
                  name="Set Type"
                  size="small"
                  value={getSelectedValue('setType')}
                  options={options.setType}
                  onChange={onDropdownChange('setType')}
                  disabled={status === 'fetching'}
                />
                <FormControl.Range
                  key={`${selection.previous}-previous`}
                  label="Previous Activity"
                  name="Previous"
                  size="small"
                  max={72}
                  min={1}
                  step={1}
                  unit="$1hrs"
                  addonStart="1hr"
                  addonEnd="72hrs"
                  onChange={setRangeValue}
                  disabled={status === 'fetching'}
                />
              </FormGrid>
            </form>
            <ToggleContainer>
              <SmallLoaderWrapper>
                {status === 'updating' && <Loader />}
              </SmallLoaderWrapper>
              <ToggleTitle>Auto-Update</ToggleTitle>
              <Toggle
                onClick={onAutoUpdateChange}
                enabled={autoUpdate}
                onLabel="On"
                offLabel="Off"
                colour="black"
              />
            </ToggleContainer>
          </Header>

          <SelectedFiltersContainer>
            {selectionLength > 0 ? (
              <>
                <FilterMessage active>
                  Filters applied ({selectionLength})
                </FilterMessage>
                <ClearFiltersButton type="reset" onClick={resetFilters}>
                  Clear All
                </ClearFiltersButton>
              </>
            ) : (
              <FilterMessage>No filters have been applied</FilterMessage>
            )}
          </SelectedFiltersContainer>

          {status === 'fetching' && (
            <MediumLoaderWrapper>
              <Loader />
            </MediumLoaderWrapper>
          )}

          {status !== 'fetching' && !activity.length && (
            <NoResults
              heading="No events found within your criteria"
              suggestions={[
                'Check your filter selection',
                `Ensure your time range is extensive enough`
              ]}
            />
          )}

          <EventsContainer>
            {status !== 'fetching' &&
              activity.map((data) => (
                <Notification key={data.activityId} data={data} />
              ))}
          </EventsContainer>
        </>
      )}
    </Container>
  );
};

export default ActivityFeed;
