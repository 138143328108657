import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import config from '../../config';

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  border: 1px solid #d0d0d0;
  padding: 2rem;
  border-radius: 0.2rem;
  font-size: 1.15rem;

  ${({ isExpanded }) =>
    isExpanded &&
    css`
      grid-column: 1/-1;
      box-shadow: 1px 1px 1px #dfdfdf, -1px -1px 1px #dfdfdf;
      border-color: #7b7b7b;
    `}

  ${({ approvalStatus }) =>
    approvalStatus &&
    css`
      border-color: ${config.colours[approvalStatus]};
    `}
`;

const ApprovalStatusLabel = styled.div`
  position: absolute;
  top: -0.9rem;
  left: 1rem;
  background-color: ${({ approvalStatus }) => config.colours[approvalStatus]};
  color: white;
  padding: 0.4rem 0.8rem 0.2rem;
  border-left: 1rem solid white;
  border-right: 1rem solid white;
  text-transform: uppercase;
  font-size: 1rem;
  letter-spacing: 0.03rem;
`;

const statusMapping = {
  approval: 'approved',
  rejection: 'rejected',
  hold: 'held'
};

const ImageFrame = forwardRef(
  ({ children, isExpanded, approvalStatus }, ref) => (
    <Container
      isExpanded={isExpanded}
      approvalStatus={approvalStatus}
      ref={ref}
      role="listitem"
    >
      {approvalStatus && (
        <ApprovalStatusLabel
          data-testid="approval-status-label"
          approvalStatus={approvalStatus}
        >
          {statusMapping[approvalStatus]}
        </ApprovalStatusLabel>
      )}
      {children}
    </Container>
  )
);

ImageFrame.propTypes = {
  children: PropTypes.node,
  isExpanded: PropTypes.bool,
  approvalStatus: PropTypes.string
};

ImageFrame.defaultProps = {
  children: null,
  isExpanded: false,
  approvalStatus: null
};

export default ImageFrame;
