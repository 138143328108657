import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const CheckImage = ({ height, width, colour }) => (
  <svg
    height={height}
    width={width}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 12 10"
  >
    <path fill={colour} d="M3.8 7.3L1.2 4.7 0 5.9l3.8 3.8L12 1.5 10.8.3z" />
  </svg>
);

CheckImage.propTypes = {
  height: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  colour: PropTypes.string.isRequired
};

const Label = styled.label`
  cursor: pointer;
  display: flex;
  position: relative;
  user-select: none;
`;

const Text = styled.span`
  font-family: Arial, sans-serif;
  font-size: 1.4rem;
  margin-left: 1.2rem;
  flex-grow: 1;
  line-height: 1.6rem;
`;

const FakeCheckbox = styled.span`
  background-color: ${({ checked, disabled }) =>
    checked && !disabled ? '#000' : '#fff'};
  border: ${({ checked, disabled }) =>
    checked && !disabled ? '0.1rem solid #000' : '0.1rem solid #cbcbcb'};
  height: 1.6rem;
  width: 1.6rem;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  ${({ disabled }) =>
    disabled &&
    `
    cursor: pointer;
    opacity: 0.6;
  `}
`;

const HiddenInput = styled.input.attrs({
  className: 'sr-only'
})`
  &:focus + ${FakeCheckbox} {
    outline: 1px dotted currentColor;
    outline: 5px auto -webkit-focus-ring-color;
  }

  &:focus + ${FakeCheckbox}:-moz-focusring {
    outline: 1px dotted ButtonText;
  }
`;

const Checkbox = ({ label, disabled, checked, onClick }) => (
  <Label>
    <HiddenInput
      checked={checked}
      type="checkbox"
      onChange={() => onClick(label)}
      disabled={disabled}
    />
    <FakeCheckbox checked={checked}>
      {checked ? <CheckImage height="60%" width="60%" colour="#fff" /> : ''}
    </FakeCheckbox>
    <Text>{label}</Text>
  </Label>
);

Checkbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  label: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

Checkbox.defaultProps = {
  label: '',
  disabled: undefined
};

Checkbox.componentName = 'Checkbox';

export default Checkbox;
