import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { DialogOverlay, DialogContent } from '@reach/dialog';
import '@reach/dialog/styles.css';
import Loader from '@yic/loader';
import Icon from '@yic/icon';

const ImageContainer = styled.div`
  position: relative;
  height: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: zoom-in;
`;

const StyledImg = styled.img`
  max-width: 100%;
  height: auto;
  display: ${({ visible }) => (visible ? 'block' : 'none')};
`;

const LoaderWrapper = styled.div`
  margin: 1rem auto;
  height: 2.5rem;
  width: 2.5rem;
`;

const ErrorWrapper = styled.div`
  margin: 2rem 0;
  display: flex;
  align-items: center;
  flex-direction: column;

  p {
    text-align: center;
    margin-top: 0.5rem;
  }
`;

const Overlay = styled(DialogOverlay)`
  display: flex;
  z-index: 100;

  && {
    background-color: rgba(0, 0, 0, 0.6);
  }

  > [data-reach-dialog-content] {
    cursor: zoom-out;
    margin: auto;
    padding: 0;
    width: auto;
    box-shadow: 0 0 2.2rem 0 rgba(0, 0, 0, 0.5);
  }
`;

const Img = ({ data }) => {
  const [status, setStatus] = useState('pending');
  const [displayFullSize, setDisplayFullSize] = useState(false);

  const fullSizeImageRef = useRef(null);

  useEffect(() => {
    if (displayFullSize) {
      setTimeout(() => {
        if (fullSizeImageRef.current) {
          fullSizeImageRef.current.scrollIntoView({
            block: 'center'
          });
        }
      }, 50);
    }
  }, [displayFullSize]);

  const onImageClick = () => setDisplayFullSize((curr) => !curr);

  return (
    <>
      {displayFullSize && (
        <Overlay
          allowPinchZoom
          isOpen={displayFullSize}
          onDismiss={onImageClick}
        >
          <DialogContent onClick={onImageClick} aria-labelledby={data.filename}>
            <StyledImg
              ref={fullSizeImageRef}
              src={data.url}
              alt={data.filename}
              visible
            />
          </DialogContent>
        </Overlay>
      )}

      <ImageContainer draggable onClick={onImageClick}>
        <StyledImg
          src={data.url}
          onLoad={() => setStatus('loaded')}
          onError={() => setStatus('error')}
          alt={data.filename}
          visible={status === 'loaded'}
        />

        {status === 'error' && (
          <ErrorWrapper>
            <Icon name="Error" size={23} colour="#000" />
            <p>Image failed to load</p>
          </ErrorWrapper>
        )}

        {status === 'pending' && (
          <div>
            <LoaderWrapper>
              <Loader />
            </LoaderWrapper>
          </div>
        )}
      </ImageContainer>
    </>
  );
};

Img.propTypes = {
  data: PropTypes.shape({
    filename: PropTypes.string,
    shotType: PropTypes.string,
    productId: PropTypes.string,
    url: PropTypes.string
  }).isRequired
};

export default Img;
