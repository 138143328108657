import React from 'react';
import PropTypes from 'prop-types';

const Tab = ({ visible, children, title, ...rest }) => (
  <div
    {...rest}
    role="tabpanel"
    tabIndex="0"
    hidden={visible ? undefined : 'hidden'}
    aria-labelledby={title}
  >
    {children}
  </div>
);

Tab.propTypes = {
  visible: PropTypes.bool,
  children: PropTypes.node.isRequired,
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired
};

Tab.defaultProps = {
  visible: false
};

export default Tab;
