import * as R from 'ramda';
import {
  FETCHING_NOTIFICATIONS,
  UPDATING_NOTIFICATIONS,
  MARKING_ALL_AS_SEEN,
  ADD_NOTIFICATIONS,
  MARK_AS_SEEN,
  NOTIFICATIONS_ERROR,
  CLEAR_ERROR
} from '../constants';

const initialState = {
  unreadCount: 0,
  notifications: [],
  status: 'fetching'
};

const markNotifications = (items, ids) =>
  items.map((i) => (ids.includes(i.activityId) ? { ...i, seen: 1 } : i));

const getUnreadCount = (items) => items.filter(({ seen }) => !seen).length;

const notifications = (state = initialState, action) => {
  switch (action.type) {
    case FETCHING_NOTIFICATIONS: {
      return {
        ...state,
        status: 'fetching',
        error: null
      };
    }
    case UPDATING_NOTIFICATIONS: {
      return {
        ...state,
        status: 'updating',
        error: null
      };
    }
    case MARKING_ALL_AS_SEEN: {
      return {
        ...state,
        status: 'markingAllAsSeen',
        error: null
      };
    }
    case ADD_NOTIFICATIONS: {
      const items = [...action.payload, ...state.notifications];
      const latestTimestamp = R.path([0, 'timestamp'], items);

      return {
        unreadCount: getUnreadCount(items),
        notifications: items,
        status: 'idle',
        latestTimestamp
      };
    }
    case MARK_AS_SEEN: {
      const ids = action.payload.map((i) => i.activityId);
      const items = markNotifications(state.notifications, ids);

      return {
        ...state,
        notifications: items,
        unreadCount: getUnreadCount(items),
        status: 'idle'
      };
    }
    case NOTIFICATIONS_ERROR:
      return {
        ...state,
        error: action.payload,
        status: 'error'
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: 'idle'
      };
    default:
      return state;
  }
};

export default notifications;
