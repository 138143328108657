/* eslint-disable import/prefer-default-export */
import { getUserNotifications, markNotificationSeen } from '../services/data';
import {
  FETCHING_NOTIFICATIONS,
  UPDATING_NOTIFICATIONS,
  MARKING_ALL_AS_SEEN,
  ADD_NOTIFICATIONS,
  MARK_AS_SEEN,
  NOTIFICATIONS_ERROR
} from './constants';

export const getNotifications = () => async (dispatch) => {
  dispatch({ type: FETCHING_NOTIFICATIONS });

  try {
    const payload = await getUserNotifications();
    dispatch({ type: ADD_NOTIFICATIONS, payload });
  } catch (err) {
    dispatch({ type: NOTIFICATIONS_ERROR, payload: err });
  }
};

export const updateNotifications = () => async (dispatch, getState) => {
  dispatch({ type: UPDATING_NOTIFICATIONS });

  const { latestTimestamp } = getState().notifications;

  try {
    const payload = await getUserNotifications({ since: latestTimestamp });
    dispatch({ type: ADD_NOTIFICATIONS, payload });
  } catch (err) {
    dispatch({ type: NOTIFICATIONS_ERROR, payload: err });
  }
};

export const addNotifications = (payload) => (dispatch) => {
  dispatch({ type: ADD_NOTIFICATIONS, payload });
};

export const markAsSeen = (itemToMark) => (dispatch) => {
  dispatch({ type: MARK_AS_SEEN, payload: [itemToMark] });

  return markNotificationSeen([itemToMark]).catch((err) =>
    dispatch({ type: NOTIFICATIONS_ERROR, payload: err })
  );
};

export const markAllAsSeen = () => async (dispatch, getState) => {
  dispatch({ type: MARKING_ALL_AS_SEEN });

  const { notifications } = getState().notifications;

  const itemsToMark = notifications
    .filter(({ seen }) => !seen)
    .map(({ activityId, timestamp }) => ({ activityId, timestamp }));

  dispatch({ type: MARK_AS_SEEN, payload: itemsToMark });

  try {
    await markNotificationSeen(itemsToMark);
  } catch (err) {
    dispatch({ type: NOTIFICATIONS_ERROR, payload: err });
  }
};
