import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ImageSubGrid from './ImageSubGrid';

const Container = styled.div`
  margin-top: 3rem;
  display: grid;
  grid-gap: 3rem;
`;

const ImageGrid = ({ results, setResults }) => (
  <Container>
    {results.map(({ productId, designer, lastUpdated, images }) => (
      <div key={`${productId}-container`}>
        <ImageSubGrid
          productId={productId}
          designer={designer}
          lastUpdated={lastUpdated}
          images={images}
          setResults={setResults}
        />
      </div>
    ))}
  </Container>
);

ImageGrid.propTypes = {
  results: PropTypes.arrayOf(
    PropTypes.shape({
      productId: PropTypes.string.isRequired,
      images: PropTypes.arrayOf(PropTypes.shape({}))
    })
  ).isRequired,
  setResults: PropTypes.func.isRequired
};

export default ImageGrid;
