import React, { useState } from 'react';
import styled from 'styled-components';
import Loader from '@yic/loader';
import qs from 'querystring';
import { Helmet } from 'react-helmet';
import ContactSheetsForm from './ContactSheetsForm';
import ContactSheetSummary from './ContactSheetSummary';
import ImageGrid from '../images/ImageGrid';
import NoResults from '../errors/NoResults';
import { getSets } from '../../services/data';
import history from '../../services/history';

const Wrapper = styled.div`
  margin: 2.5rem 2.9rem;
  display: flex;

  flex-direction: column;
`;

const LoaderWrapper = styled.div`
  margin: 4rem auto;
  height: 6rem;
  width: 6rem;
`;

const ErrorMessage = styled.div`
  margin-top: 2rem;
  color: #d7041e;
  font-size: 1.4rem;
`;

const ContactSheets = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [results, setResults] = useState(false);
  const [error, setError] = useState(false);

  const submitSearch = async (filters) => {
    history.push(`/contact-sheets?${qs.stringify(filters)}`);

    setIsFetching(true);

    setResults(false);
    setError(false);

    try {
      const images = await getSets(filters);
      setResults(images);
    } catch (err) {
      setError(true);
    }

    setIsFetching(false);
  };

  return (
    <Wrapper>
      <Helmet>
        <title>Contact Sheets - Backstage</title>
      </Helmet>
      <ContactSheetsForm submitSearch={submitSearch} isFetching={isFetching} />
      {isFetching && (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      )}
      {error && (
        <ErrorMessage>
          Something went wrong. Please contact service desk.
        </ErrorMessage>
      )}
      {results && !results.length && (
        <NoResults
          heading="Sorry, no images found matching your selection"
          suggestions={[
            'Check your filter selections',
            'Verify images are being uploaded to the correct set'
          ]}
        />
      )}

      {!!results.length && (
        <>
          <ContactSheetSummary results={results} />
          <ImageGrid results={results} setResults={setResults} />
        </>
      )}
    </Wrapper>
  );
};

export default ContactSheets;
