import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import styled from 'styled-components';
import { ActionMessage } from '@fabric/messaging';
import Icon from '@yic/icon';
import moment from 'moment';
import Image from './Image';
import BulkApprovalStatusButtons from '../BulkStatusButtons';
import { setImageStatus } from '../../services/data';
import {
  applyBulkStatusChange,
  applySingleStatusChange
} from '../../utils/imageStatus';

const ImageGridHeading = styled.div`
  font-family: Georgia, sans-serif;
  font-size: 1.6rem;
  margin: 0 0 2.5rem 0.2rem;
  display: flex;
  flex-direction: row;
  align-items: center;

  span {
    margin-right: 1rem;
  }
`;

const ProductId = styled.span`
  font-size: 2rem;
`;

const LastUploadTime = styled.span`
  font-style: italic;
  font-size: 1.4rem;
`;

const HorizontalLine = styled.div`
  height: 0.8rem;
  border-bottom: 1px dotted #e1e1e1;
  flex-grow: 1;
`;

const BulkStatusButtons = styled(BulkApprovalStatusButtons)`
  margin: 0.75rem 0 0 1rem;
`;

const ImagesGrid = styled.div`
  display: grid;
  align-items: center;
  grid-gap: 1.5rem;
  grid-template-columns: repeat(6, 1fr);

  @media only screen and (max-width: 4000px) {
    grid-template-columns: repeat(5, 1fr);
  }

  @media only screen and (max-width: 3000px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media only screen and (max-width: 2000px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media only screen and (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media only screen and (max-width: 800px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const ActionMessageContainer = styled.div`
  margin-bottom: 3rem;
`;

const ActionMessageContent = styled.div`
  display: flex;
  align-items: center;

  /* necessary to vertically center icon */
  & svg {
    align-self: initial;
    margin-right: 1rem;
  }
`;

const areAllStatusesTheSame = R.pipe(
  R.groupBy(R.prop('approvalStatus')),
  R.values,
  R.length,
  R.equals(1)
);

const getApprovalStatus = (images) => {
  const allSame = areAllStatusesTheSame(images);
  return allSame ? images[0].approvalStatus : null;
};

const ImageSubGrid = ({
  productId,
  designer,
  lastUpdated,
  images,
  setResults
}) => {
  const [inlineError, setInlineError] = useState();

  const bulkApprovalStatus = getApprovalStatus(images);

  const updateAllImageStatuses = async (type) => {
    const approvalStatus = bulkApprovalStatus === type ? null : type;

    try {
      await setImageStatus({
        images: R.map(R.pick(['filename', 'business']))(images),
        approvalStatus
      });

      setResults(applyBulkStatusChange(productId, approvalStatus));
    } catch (err) {
      setInlineError({
        message: type
          ? `Error applying approval status to all images for ${productId}.`
          : `Error removing approval status from all images for ${productId}.`
      });
    }
  };

  const updateSingleImageStatus = (filename, business) => async (
    approvalStatus
  ) => {
    await setImageStatus({
      images: [{ filename, business }],
      approvalStatus
    });
    setResults(applySingleStatusChange(productId, filename, approvalStatus));
  };

  return (
    <div role="list">
      <ImageGridHeading>
        <div>
          <ProductId>{productId}</ProductId>
          <span>-</span>
          <span>{designer}</span>
          <span>-</span>
          <LastUploadTime>
            Last upload {moment(lastUpdated).fromNow()}
          </LastUploadTime>
        </div>
        <HorizontalLine />
        {images.length > 0 && (
          <BulkStatusButtons
            bulkUpdateStatus={updateAllImageStatuses}
            bulkApprovalStatus={bulkApprovalStatus}
          />
        )}
      </ImageGridHeading>
      {inlineError && (
        <ActionMessageContainer>
          <ActionMessage type="warning" staticPlacement visible>
            <ActionMessageContent>
              <Icon name="error" colour="#B60218" />
              <span>{inlineError.message}</span>
            </ActionMessageContent>
          </ActionMessage>
        </ActionMessageContainer>
      )}
      <ImagesGrid>
        {images.map((image) => (
          <Image
            key={image.filename}
            data={image}
            updateImageStatus={updateSingleImageStatus(
              image.filename,
              image.business
            )}
          />
        ))}
      </ImagesGrid>
    </div>
  );
};

ImageSubGrid.propTypes = {
  productId: PropTypes.string.isRequired,
  designer: PropTypes.string.isRequired,
  lastUpdated: PropTypes.number.isRequired,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      approvalStatus: PropTypes.string
    })
  ).isRequired,
  setResults: PropTypes.func.isRequired
};

export default ImageSubGrid;
