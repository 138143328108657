import config from '../config';

let client;

export const initialiseWS = ({ type, authorization }) => {
  const socketTypeParams = new URLSearchParams();
  socketTypeParams.append('type', type);
  socketTypeParams.append('authorization', authorization);

  const WEBSOCKET_API_URL = `${
    config.webSocketHost
  }?${socketTypeParams.toString()}`;

  client = new WebSocket(WEBSOCKET_API_URL);
  return client;
};

export const getWS = (params) => initialiseWS(params);
