import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Icon from '@yic/icon';
import { ActionMessage } from '@fabric/messaging';
import AsyncButton from './AsyncButton';
import { getUserActionsPermissions } from '../utils/permissions';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  & > * {
    margin-top: 2rem;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const StatusButton = styled(AsyncButton)`
  && {
    min-width: 9.5rem;
  }
  & + & {
    margin-left: 1.6rem;
  }
`;

const ActionMessageContent = styled.div`
  display: flex;
  align-items: center;

  /* necessary to vertically center icon */
  & svg {
    align-self: initial;
    margin-right: 1rem;
  }
`;

const StatusButtons = ({ currentStatus, updateImageStatus, className }) => {
  const [loadingType, setLoadingType] = useState(null);
  const [inlineError, setInlineError] = useState();

  const onStatusButtonClick = (type) => async () => {
    const approvalStatus = currentStatus === type ? null : type;

    setLoadingType(type);

    try {
      await updateImageStatus(approvalStatus);
      setInlineError();
    } catch {
      setInlineError({
        message: approvalStatus
          ? `Error applying approval status to this image.`
          : `Error unsetting approval status for this image.`
      });
    }

    setLoadingType(null);
  };

  const { canApprove } = getUserActionsPermissions();

  return canApprove ? (
    <Container>
      {inlineError && (
        <ActionMessage type="warning" staticPlacement visible>
          <ActionMessageContent>
            <Icon name="error" colour="#B60218" />
            <span>{inlineError.message}</span>
          </ActionMessageContent>
        </ActionMessage>
      )}
      <ButtonsContainer className={className}>
        <StatusButton
          selected={currentStatus === 'approval'}
          size="small"
          onClick={onStatusButtonClick('approval')}
          isLoading={loadingType === 'approval'}
          title={
            currentStatus === 'approval'
              ? 'Remove approval status'
              : 'Approve image'
          }
        >
          <Icon name="confirmation" colour="green" />
          {currentStatus === 'approval' ? 'Approved' : 'Approve'}
        </StatusButton>
        <StatusButton
          selected={currentStatus === 'hold'}
          size="small"
          onClick={onStatusButtonClick('hold')}
          isLoading={loadingType === 'hold'}
          title={
            currentStatus === 'hold' ? 'Remove approval status' : 'Hold image'
          }
        >
          <Icon name="warning" colour="darkorange" />
          {currentStatus === 'hold' ? 'Held' : 'Hold'}
        </StatusButton>
        <StatusButton
          selected={currentStatus === 'rejection'}
          size="small"
          onClick={onStatusButtonClick('rejection')}
          isLoading={loadingType === 'rejection'}
          title={
            currentStatus === 'rejection'
              ? 'Remove approval status'
              : 'Reject image'
          }
        >
          <Icon name="error" colour="#B60218" />
          {currentStatus === 'rejection' ? 'Rejected' : 'Reject'}
        </StatusButton>
      </ButtonsContainer>
    </Container>
  ) : null;
};

StatusButtons.propTypes = {
  currentStatus: PropTypes.string,
  updateImageStatus: PropTypes.func.isRequired,
  className: PropTypes.string
};

StatusButtons.defaultProps = {
  currentStatus: null,
  className: ''
};

export default StatusButtons;
