import React, { Children } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Button from '@yic/button';
import classnames from '@yic/classnames';

const FocusBar = styled.div`
  background-color: #cccccc;
  position: absolute;
  width: calc(100% + 0.2rem);
  height: 0.4rem;
  bottom: -0.1rem;
  left: -0.1rem;
  display: none;
`;

const TabButton = styled(Button.Primary)`
  position: relative;
  background-color: #f0f0f0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border: none;
  border-top: 0.1rem solid #f0f0f0;
  border-left: 0.1rem solid #f0f0f0;
  border-right: 0.1rem solid #f0f0f0;

  color: black;
  font-weight: bold;
  height: 4.3rem;
  line-height: 1.16667;
  padding: 1rem 1.5rem 0.6rem;

  &&[disabled] {
    color: #f0f0f0;

    ${FocusBar} {
      display: none;
    }
  }

  &:hover,
  &:focus {
    background-color: #f0f0f0;
    border: none;
    border-top: 0.1rem solid #f0f0f0;
    border-left: 0.1rem solid #f0f0f0;
    border-right: 0.1rem solid #f0f0f0;
    color: black;

    ${FocusBar} {
      display: block;
    }
  }

  & + & {
    margin-left: 0.5rem;
  }

  &.medium,
  &.small {
    font-size: 1.2rem;
  }

  &.medium {
    min-width: 9rem;
    padding: 1rem 1.5rem 0.6rem;
    width: 14.5rem;
    max-width: 14.5rem;
  }

  &.small {
    min-width: 10rem;
    padding: 1rem 1.5rem 0.6rem;
    width: 10rem;

    svg {
      margin: 0 auto;
      float: none;
    }
  }

  svg {
    fill: white;
  }

  &.active {
    background-color: #fff;
    border-top: 0.1rem solid #f0f0f0;
    border-left: 0.1rem solid #f0f0f0;
    border-right: 0.1rem solid #f0f0f0;
    box-shadow: 0 0.2rem 0 white;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    color: black;

    svg {
      fill: black;
    }

    ${FocusBar} {
      display: none;
    }

    &:hover,
    &:focus {
      background-color: #fff;
      box-shadow: 0 0.4rem 0 white;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      color: black;
    }
  }

  &:hover,
  &:focus {
    background-color: #f0f0f0;
    color: black;
    border-top: 0.1rem solid #f0f0f0;
    border-left: 0.1rem solid #f0f0f0;
    border-right: 0.1rem solid #f0f0f0;
  }
`;

const TabList = styled.div`
  background-color: transparent;
  display: flex;
  list-style-type: none;
  margin: 0;
  flex-grow: 1;
  position: relative;
  padding: 0 1rem;
  width: 100%;
  z-index: 1;
  border-bottom: 1px solid #f0f0f0;

  &.stretch ${TabButton} {
    width: 100%;
  }

  @media (min-width: 768px) {
    justify-content: flex-start;
  }
`;

const ImageCount = styled.div`
  position: absolute;
  top: 0.6rem;
  right: 0.6rem;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  padding: 0.24rem 0.5rem;
  background-color: black;
  color: white;
  font-size: 0.8rem;
  height: 1.4rem;
`;

const Navigation = ({ size, activeTab, onClick, tabCount, children }) => {
  const handleClick = (i) => (event) => {
    event.preventDefault();
    onClick(i);
  };

  return (
    <TabList
      role="tablist"
      className={classnames({ stretch: tabCount - 1 < 3 })}
    >
      {Children.map(children, (child, index) => {
        const { title, count, visible, ...props } = child.props;
        const isActive = activeTab === index;

        return (
          <TabButton
            {...props}
            key={title}
            size={size}
            role="tab"
            aria-selected={isActive ? 'true' : 'false'}
            className={classnames({
              size,
              active: isActive
            })}
            onClick={handleClick(index)}
            tabIndex={isActive ? '0' : '-1'}
          >
            {title}
            <FocusBar />
            {count > 0 && (
              <ImageCount>
                <div>{count}</div>
              </ImageCount>
            )}
          </TabButton>
        );
      })}
    </TabList>
  );
};

Navigation.propTypes = {
  children: PropTypes.node.isRequired,
  activeTab: PropTypes.number,
  size: PropTypes.oneOf(['large', 'medium', 'small']),
  onClick: PropTypes.func,
  tabCount: PropTypes.number
};

Navigation.defaultProps = {
  activeTab: 1,
  size: 'large',
  onClick: () => {},
  tabCount: 0
};

export default Navigation;
