export const applyBulkStatusChange = (productId, approvalStatus) => (results) =>
  results.map((result) =>
    result.productId === productId
      ? {
          ...result,
          images: result.images.map((img) => ({ ...img, approvalStatus }))
        }
      : result
  );

export const applySingleStatusChange = (
  productId,
  filename,
  approvalStatus
) => (results) =>
  results.map((result) => {
    if (result.productId === productId) {
      return {
        ...result,
        images: result.images.map((img) => {
          if (img.filename === filename) {
            return { ...img, approvalStatus };
          }
          return img;
        })
      };
    }

    return result;
  });
