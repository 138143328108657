import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Icon from '@yic/icon';
import moment from 'moment';
import decode from 'jwt-decode';
import * as R from 'ramda';
import ImageDetails from '../images/ImageDetails';
import { markAsSeen } from '../../redux/actions';
import { getToken } from '../../services/authentication';
import config from '../../config';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  border: 1px solid #d0d0d0;
  border-radius: 0.2rem;
  font-size: 1.15rem;
  opacity: ${({ faded }) => (faded ? 0.9 : 1)};

  :hover {
    opacity: 1;
    border: 1px solid #7b7b7b;
  }

  ${({ isExpanded }) =>
    isExpanded &&
    css`
      box-shadow: 1px 1px 1px #dfdfdf, -1px -1px 1px #dfdfdf;
      border-color: #7b7b7b;
    `}
`;

const Header = styled.div`
  padding: 0.9rem 1.2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: Arial, sans-serif;
  font-size: 1.3rem;
  cursor: pointer;

  ${({ isExpanded, isFetching }) =>
    (isExpanded || isFetching) &&
    css`
      border-bottom: 1px solid #d0d0d0;

      :hover {
        border-bottom: 1px solid #7b7b7b;
      }
    `}
`;

const NewText = styled.div`
  font-family: Georgia, sans-serif;
  font-size: 1.2rem;
  margin-right: 0.8rem;
`;

const Type = styled.div`
  padding: 0.36rem 0.6rem;
  border-radius: 0.2rem;
  color: white;
  text-transform: uppercase;
  font-size: 0.9rem;
  margin-right: 1rem;

  ${({ colours: { colour, background, border } }) => css`
    color: ${colour};
    background-color: ${background};
    border: 0.1rem solid ${border};
  `}
`;

const Name = styled.span`
  font-weight: bold;
`;

const Action = styled.span`
  display: flex;
  align-items: center;
  flex-grow: 1;
  margin-left: 0.4rem;
  letter-spacing: 0.01rem;
`;

const Content = styled.div`
  display: flex;
  margin: 0 0.4rem;
  font-style: italic;
  background-color: #ececec;
  padding: 0.2rem 0.5rem;
`;

const ContentText = styled.div`
  max-width: 30rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
`;

const Time = styled.div`
  font-size: 1.2rem;
  color: #515151;
  margin-right: 1rem;
  flex-shrink: 0;
  font-style: italic;
`;

const UnderlinedText = styled.div`
  text-decoration: underline;
  text-transform: uppercase;
  margin: 0 0.4rem;
`;

const DetailsContainer = styled.div`
  padding: 1rem 2rem;
`;

const descriptionText = {
  comment: 'on the',
  upload: 'uploaded a',
  reupload: 'uploaded a new',
  option: 'added an option to the',
  approval: 'approved the',
  rejection: 'rejected the',
  hold: 'placed a hold on the'
};

const getActionContent = ({ type, content, shotType, productId }) => (
  <>
    {type === 'comment' && (
      <>
        wrote:
        <Content>
          &apos;
          <ContentText>{content}</ContentText>
          &apos;
        </Content>
      </>
    )}
    {descriptionText[type]}
    <UnderlinedText>{shotType}</UnderlinedText>
    for
    <UnderlinedText>{productId}</UnderlinedText>
  </>
);

getActionContent.propTypes = {
  type: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  shotType: PropTypes.string.isRequired,
  productId: PropTypes.string.isRequired
};

const getColours = (type) => {
  switch (type) {
    case 'upload':
    case 'reupload':
    case 'option':
    case 'approval':
    case 'rejection':
    case 'hold':
      return {
        colour: '#FFFFFF',
        background: config.colours[type],
        border: config.colours[type]
      };
    case 'comment':
    default:
      return { colour: '#000000', background: '#FFFFFF', border: '#000000' };
  }
};

const getTabNumber = (type) => {
  switch (type) {
    case 'reupload':
      return 3;
    case 'option':
      return 1;
    case 'upload':
    case 'comment':
    case 'approval':
    case 'rejection':
    case 'hold':
    default:
      return 2;
  }
};

const isSelf = R.converge(R.equals, [
  R.pipe(getToken, decode, R.path(['user', 'email'])),
  R.identity
]);

const Notification = ({ data }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedTab, setSelectedTab] = useState();

  const dispatch = useDispatch();

  const { activityId, timestamp, type, seen, userName, userEmail, fao } = data;

  const onDetailsToggle = () => {
    if (fao !== 'all' && !seen) {
      dispatch(markAsSeen({ activityId, timestamp }));
    }

    setIsExpanded(!isExpanded);
    setSelectedTab(getTabNumber(type));
  };

  return (
    <Container isExpanded={isExpanded} faded={!isExpanded && seen}>
      <Header isExpanded={isExpanded} onClick={onDetailsToggle}>
        {fao !== 'all' && !seen && <NewText>New</NewText>}

        <Type colours={getColours(type)}>{type}</Type>

        <Name>{isSelf(userEmail) ? 'You' : userName}</Name>

        <Action>{getActionContent(data)}</Action>

        <Time>{moment(timestamp).fromNow()}</Time>

        <Icon name={`arrow-${isExpanded ? 'up' : 'down'}`} size={15} />
      </Header>

      {isExpanded && (
        <DetailsContainer>
          <ImageDetails
            image={data}
            close={onDetailsToggle}
            selectedTab={selectedTab}
            showImageStatusButtons
          />
        </DetailsContainer>
      )}
    </Container>
  );
};

Notification.propTypes = {
  data: PropTypes.shape({
    activityId: PropTypes.string,
    shotType: PropTypes.string,
    userName: PropTypes.string,
    userEmail: PropTypes.string,
    type: PropTypes.string,
    timestamp: PropTypes.number,
    seen: PropTypes.number,
    fao: PropTypes.string
  }).isRequired
};

export default Notification;
