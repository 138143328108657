import React from 'react';
import styled from 'styled-components';
import Icon from '@yic/icon';
import Heading from '@yic/heading';

const Container = styled.div`
  font-size: 1.6rem;
  padding: 1rem;
`;

const Contact = styled.div`
  margin: 3rem 0;
`;

const IconContainer = styled.div`
  margin: 0 1rem;
`;

const Message = styled.div`
  display: flex;
  font-size: 1.4rem;
  background-color: #e0effa;
  color: #103855;
  margin: 1.4rem 0 0;
  padding: 1rem 0;
  width: 50%;
  border-radius: 0.2rem;

  span {
    padding-top: 0.3rem;
  }

  ul {
    margin-bottom: 0.5rem;
    padding-left: 1rem;
  }
  li {
    margin-top: 1rem;
  }
`;

const Ul = styled.ul`
  list-style: none;
  padding: 0;

  svg {
    display: inline-block;
    top: 0.5rem;
    position: relative;
  }

  p {
    display: inline-block;
    margin-left: 1.1rem;
  }
`;

const Anchor = styled.a`
  text-decoration: underline;

  &:hover {
    color: #000;
  }
`;

const Forbidden = () => {
  return (
    <Container>
      <Heading.Level1>Access Denied</Heading.Level1>
      <p>Sorry, you do not have permission to access Backstage</p>
      <Message>
        <IconContainer>
          <Icon name="Information" size={23} colour="#103855" />
        </IconContainer>
        <span>
          You must be in one of these security groups to gain access:
          <ul>
            <li>BACKSTAGE_READER</li>
            <i>Read only access</i>
            <li>BACKSTAGE_CONTRIBUTOR</li>
            <i>Read access with the ability to comment</i>
            <li>BACKSTAGE_APPROVER</li>
            <i>All of the above plus the ability to approve images</i>
          </ul>
        </span>
      </Message>
      <Contact>
        <Heading.Level4>
          To request access, please state your desired group in the form below:
        </Heading.Level4>
        <Ul>
          <li>
            <Icon name="external-link" size={24} colour="#010101" />
            <p>
              <Anchor
                href="https://ynap.service-now.com/sp?id=sc_cat_item&sys_id=eeb5450cdb854410679fdbe6f49619ea"
                target="_blank"
              >
                Service Now - Request Access Permissions
              </Anchor>
            </p>
          </li>
        </Ul>
        <p>Your request will be sent to your manager for approval</p>
      </Contact>
    </Container>
  );
};

export default Forbidden;
