import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import Loader from '@yic/loader';
import Button from '@yic/button';
import Icon from '@yic/icon';
import { updateNotifications, markAllAsSeen } from '../../redux/actions';
import { CLEAR_ERROR } from '../../redux/constants';
import Notification from './Notification';
import ErrorMessage from '../errors/ErrorMessage';
import AsyncButton from '../AsyncButton';

const Container = styled.div`
  display: grid;
  margin: 2.5rem 2.9rem;
  grid-row-gap: 1rem;
`;

const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    margin: 0.2rem 0;
    font-family: Arial, sans-serif;
    font-size: 1.3rem;
    font-style: italic;
  }

  > div:first-of-type {
    margin: 1rem auto;
    height: 4rem;
    width: 4rem;
  }
`;

const MarkAllAsReadButton = styled(Button.Secondary)`
  margin-right: 1.5rem;
`;

const Header = styled.div`
  margin: 0 0 1rem;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

const UnreadMessage = styled.div`
  font-size: 1.8rem;
  font-family: Georgia, sans-serif;
  font-style: italic;
  flex-grow: 1;
`;

const UnreadCount = styled.span`
  font-size: 2.2rem;
`;

const plural = (numberOfOptions) => (numberOfOptions !== 1 ? 's' : '');

const Notifications = () => {
  const notifications = useSelector((s) => s.notifications.notifications);
  const unreadCount = useSelector((s) => s.notifications.unreadCount);
  const status = useSelector((s) => s.notifications.status);
  const error = useSelector((s) => s.notifications.error);

  const dispatch = useDispatch();

  useEffect(() => () => dispatch({ type: CLEAR_ERROR }), [dispatch]);

  return (
    <Container>
      <Helmet>
        <title>Notifications - Backstage</title>
      </Helmet>

      {status === 'error' && (
        <ErrorMessage
          type="Notifications"
          code={error.code}
          message={error.message}
        />
      )}

      {status === 'fetching' && (
        <LoaderContainer>
          <div>
            <Loader />
          </div>
          <p>Fetching notifications, please wait...</p>
        </LoaderContainer>
      )}

      {!['fetching', 'error'].includes(status) && (
        <>
          <Header>
            <UnreadMessage>
              {unreadCount ? <UnreadCount>{unreadCount}</UnreadCount> : 'No'}
              {` unread notification${plural(unreadCount)}`}
            </UnreadMessage>
            <MarkAllAsReadButton
              onClick={() => dispatch(markAllAsSeen())}
              size="small"
            >
              <Icon name="confirmation" colour="green" />
              Mark all as Read
            </MarkAllAsReadButton>

            <AsyncButton
              size="small"
              onClick={() => dispatch(updateNotifications())}
              disabled={status === 'updating'}
              isLoading={status === 'updating'}
            >
              <Icon name="refresh" colour="#103855" />
              Refresh
            </AsyncButton>
          </Header>

          {notifications.map((data) => (
            <Notification key={data.activityId} data={data} />
          ))}
        </>
      )}
    </Container>
  );
};

export default Notifications;
