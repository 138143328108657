import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import AppHeader from './components/AppHeader';
import Routes from './Routes';
import Forbidden from './components/errors/Forbidden';
import { isUserAllowedAccess } from './utils/permissions';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
  }
`;

const Container = styled.div`
  display: block;
  margin-left: auto;
  margin-right: auto;
  min-height: 100vh;
  max-width: 100%;
  width: 100%;
`;

function App() {
  return isUserAllowedAccess() ? (
    <>
      <GlobalStyle />
      <Container>
        <AppHeader />
        <Routes />
      </Container>
    </>
  ) : (
    <Forbidden />
  );
}

export default App;
