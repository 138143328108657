import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Heading from '@yic/heading';
import Icon from '@yic/icon';
import * as R from 'ramda';
import qs from 'querystring';
import * as clipboard from 'clipboard-polyfill';

const Container = styled.div`
  margin-top: 2.5rem;
`;

const HeadingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  :hover {
    cursor: pointer;
  }
`;

const SummaryHeading = styled(Heading.Level5)`
  user-select: none;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const SummaryHeadingPrefix = styled.span`
  margin-right: 0.5rem;
  text-decoration: none;
`;

const ArrowContainer = styled.div`
  padding-top: 0.2rem;
`;

const SummaryHeadingData = styled.span`
  font-style: italic;
  margin-left: 0.5rem;

  ${HeadingWrapper}:hover & {
    text-decoration: underline;
  }
`;

const SummaryBody = styled.div`
  margin-top: 1.5rem;
  padding: 2rem 3rem;
  font-size: 1.4rem;
  background-color: #f9f9f9;
  border-radius: 0.2rem;
  cursor: text;

  :hover {
    background-color: #f7f7f7;
  }
`;

const ProductDetailsTable = styled.table`
  letter-spacing: 0.01rem;

  th {
    text-align: left;
    min-width: 11rem;
    padding-bottom: 1.4rem;
  }

  td {
    padding-right: 1.5rem;
    padding-bottom: 0.6rem;
  }
`;

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ActionLink = styled.div`
  margin: 2rem 1rem 0 0;
  font-size: 1.3rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  user-select: none;

  :hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const ActionIconWrapper = styled.div`
  margin-right: 0.5rem;
`;

const getTotals = R.applySpec({
  pids: R.pipe(R.pluck('productId'), R.length),
  images: R.pipe(R.pluck('images'), R.flatten, R.length)
});

const getProductIdsAndDesigners = R.map(
  R.applySpec({
    productId: R.prop('productId'),
    designer: R.prop('designer'),
    shotTypes: R.pipe(R.prop('images'), R.pluck('shotType'), R.join(', '))
  })
);

const Summary = ({ results }) => {
  const [bodyVisible, setBodyVisible] = useState(false);
  const [wasCopied, setWasCopied] = useState(false);

  useEffect(() => {
    const onEscapeKey = ({ keyCode }) =>
      keyCode === 27 && setBodyVisible(false);

    if (bodyVisible) document.addEventListener('keydown', onEscapeKey);

    return () => {
      if (bodyVisible) document.removeEventListener('keydown', onEscapeKey);
    };
  }, [bodyVisible]);

  const queryParms = qs.parse(window.location.search);

  const productIdsAndDesigners = getProductIdsAndDesigners(results);
  const totals = getTotals(results);

  const mailSubject = Object.values(queryParms).join(', ');
  const mailBody = [
    `Link: ${window.location.href}\n`,
    `Photographer: ${queryParms.photographer ? queryParms.photographer : ''}`,
    'Stylist:',
    'S&F:',
    'Assist:',
    'Model:',
    'Video:\n',
    ...R.reverse(productIdsAndDesigners).map(
      ({ productId, designer }, i) => `${i + 1}. ${productId} - ${designer}`
    )
  ].join('\n');

  const onCopyClick = () => {
    const dt = new clipboard.DT();

    dt.setData('text/plain', mailBody);

    clipboard.write(dt);

    setWasCopied(true);

    setTimeout(() => setWasCopied(false), 1000);
  };

  return (
    <Container>
      <HeadingWrapper onClick={() => setBodyVisible((curr) => !curr)}>
        <SummaryHeading>
          <SummaryHeadingPrefix>Summary: </SummaryHeadingPrefix>
          <ArrowContainer>
            <Icon name={bodyVisible ? 'arrow-down' : 'arrow-right'} size={12} />
          </ArrowContainer>
          <SummaryHeadingData>
            {totals.pids} Products, {totals.images} Images
          </SummaryHeadingData>
        </SummaryHeading>
      </HeadingWrapper>
      {bodyVisible && (
        <SummaryBody>
          <ProductDetailsTable>
            <tbody>
              <tr>
                <th>Product ID</th>
                <th>Designer</th>
                <th>Images</th>
              </tr>
              {productIdsAndDesigners.map(
                ({ productId, designer, shotTypes }) => (
                  <tr key={`${productId}-${designer}`}>
                    <td>{productId}</td>
                    <td>{designer}</td>
                    <td>{shotTypes}</td>
                  </tr>
                )
              )}
            </tbody>
          </ProductDetailsTable>
          <ActionsContainer>
            <ActionLink>
              <ActionIconWrapper>
                <Icon name="mail" size={12} />
              </ActionIconWrapper>
              <a
                href={`mailto:?subject=${mailSubject}&body=${encodeURIComponent(
                  mailBody
                )}`}
              >
                Email
              </a>
            </ActionLink>
            <ActionLink onClick={onCopyClick}>
              <ActionIconWrapper>
                <Icon
                  name={wasCopied ? 'confirmation' : 'copy'}
                  size={12}
                  colour={wasCopied ? 'green' : 'black'}
                />
              </ActionIconWrapper>
              <span>
                {wasCopied ? 'Copied to your clipboard!' : 'Copy to clipboard'}
              </span>
            </ActionLink>
          </ActionsContainer>
        </SummaryBody>
      )}
    </Container>
  );
};

Summary.propTypes = {
  results: PropTypes.arrayOf(
    PropTypes.shape({
      productId: PropTypes.string.isRequired,
      designer: PropTypes.string.isRequired,
      images: PropTypes.arrayOf(PropTypes.shape({}))
    })
  ).isRequired
};

export default Summary;
