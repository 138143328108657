import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { ActionMessage } from '@yic/messaging';
import { Helmet } from 'react-helmet';
import Button from '@yic/button';
import Icon from '@yic/icon';
import Loader from '@yic/loader';
import history from '../../services/history';
import ImageGrid from '../images/ImageGrid';
import NoResults from '../errors/NoResults';
import { searchImages } from '../../services/data';
import { parseSearchUrlQuery } from '../../utils/url';

const Container = styled.div`
  margin: 2.5rem 2.9rem;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-column-gap: 2rem;
`;

const SearchForm = styled.form`
  display: flex;
`;

const SearchInput = styled.input`
  flex-grow: 1;
  border: 0.1rem solid #cbcbcb;
  border-radius: 2px 0 0 2px;
  font-size: 1.6rem;
  padding: 0 1rem;
  line-height: 1.5rem;
  z-index: 1;
  letter-spacing: 0.1rem;
`;

const SearchButton = styled(Button.Primary)`
  && {
    margin: 0;
    padding: 0.9rem;
    border-radius: 0 2px 2px 0;

    > svg {
      height: 2.8rem;
      width: 2.8rem;
    }
  }

  &[disabled] > svg {
    cursor: not-allowed;
    fill: currentColor;
  }
`;

const HintMessage = styled(ActionMessage)`
  white-space: nowrap;

  div {
    line-height: 2rem;
    margin-left: 1rem;
  }
`;

const ResultsWrapper = styled.div`
  grid-column: 1/3;
`;

const LoaderWrapper = styled.div`
  margin: 4rem auto;
  height: 6rem;
  width: 6rem;
`;

const ErrorMessage = styled.div`
  margin-top: 2rem;
  color: #d7041e;
  font-size: 1.4rem;
`;

const parseSearchInput = (input) => {
  const values = input.split(/[,\s]/).filter(Boolean);
  return [...new Set(values)];
};

const labelText = 'Search for images by Product ID';

const ProductSearch = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [autoSearch, setAutoSearch] = useState(false);
  const [results, setResults] = useState(false);
  const [error, setError] = useState(false);
  const [query, setQuery] = useState('');

  const submitSearch = useCallback(
    async (event) => {
      if (event) {
        event.preventDefault();
      }

      setResults(false);

      const productIds = parseSearchInput(query);

      if (!productIds.length) {
        setError('Please enter at least one Product ID.');
        return;
      }

      history.push(`/search?productIds=${productIds.join(',')}`);

      setQuery(productIds.join(', '));

      setIsFetching(true);
      setError(false);

      try {
        const images = await searchImages({ productId: productIds });
        setResults(images);
      } catch (err) {
        setError('Something went wrong. Please contact service desk.');
      }

      setIsFetching(false);
    },
    [query]
  );

  useEffect(() => {
    if (history.location.search) {
      const productIds = parseSearchUrlQuery(history.location.search);

      if (productIds) {
        setQuery(productIds);
        setAutoSearch(true);
      }
    }
  }, []);

  useEffect(() => {
    if (autoSearch) {
      setAutoSearch(false);
      submitSearch();
    }
  }, [autoSearch, submitSearch]);

  return (
    <Container>
      <Helmet>
        <title>Search - Backstage</title>
      </Helmet>
      <SearchForm onSubmit={submitSearch}>
        <SearchInput
          aria-label={labelText}
          autoFocus
          type="text"
          value={query}
          onChange={({ target }) => setQuery(target.value)}
        />
        <SearchButton
          size="small"
          title="Submit Search"
          type="submit"
          disabled={isFetching}
        >
          <Icon name="Search" colour="white" size={16} />
        </SearchButton>
      </SearchForm>

      <HintMessage type="information" visible>
        <Icon name="Information" colour="#103855" />
        <div>{labelText}</div>
      </HintMessage>

      <ResultsWrapper>
        {isFetching && (
          <LoaderWrapper>
            <Loader />
          </LoaderWrapper>
        )}

        {error && <ErrorMessage>{error}</ErrorMessage>}

        {results && !results.length && (
          <NoResults
            heading="Sorry, no images found"
            suggestions={[
              'Check the Product IDs you have entered are valid',
              'Verify images have been uploaded for them'
            ]}
          />
        )}

        {!!results.length && (
          <ImageGrid results={results} setResults={setResults} />
        )}
      </ResultsWrapper>
    </Container>
  );
};

export default ProductSearch;
